import React, { Component } from 'react'
import { View } from 'react-native'
import { getFieldUISchemaData } from '../../../utils/fieldutils/fieldType'
import ErrorMessage from '../../form/ErrorMessage'
import PropTypes from 'prop-types'
import fieldTypes from '../../../constants/fieldTypes'
import { TextArea } from './TextArea'
import { TextInput } from './TextInput'
import _ from 'lodash'

export class InputField extends Component {
  state = {}

  changeFieldValue = (value) => {
    const {
      field: { id },
      onValuesChange
    } = this.props
    onValuesChange(id, value)
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema, fieldType },
      t,
      disabled,
      fieldAnswer,
      form,
    } = this.props
    const crfData = { ...fieldAnswer }
    return (
      <View
        style={{
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
          ...( _.isEqual(fieldType, fieldTypes.TEXTAREA) ? { marginHorizontal: 10 } : {}),
        }}
      >
        {form.getFieldDecorator(id, {
          initialValue: crfData ? crfData.fieldValue : null,
          rules: isRequired && !disabled
        ? [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
          },
        ]
        : [],
        })(
          _.isEqual(fieldType, fieldTypes.TEXTAREA) ? 
          <TextArea
            changeFieldValue={this.changeFieldValue}
            disabled={disabled}
            t={t}
            value={crfData ? crfData.fieldValue : null}
            />
          : <TextInput
            changeFieldValue={this.changeFieldValue}
            disabled={disabled}
            t={t}
            value={crfData ? crfData.fieldValue : null}
          />
        )}
        <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />
      </View>
    )
  }
}

InputField.defaultProps = {
  field: {},
  t: (key) => key,
  disabled: false,
  fieldAnswer: {},
  form: {},
  onValuesChange: () => {},
};

InputField.propTypes = {
  field: PropTypes.object,
  t: PropTypes.func,
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.object,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
};