import createActionType from '../utils/action'
import _ from 'lodash'
import constants from '../constants/constants'
import { getUserId, getUserType } from '../selectors/user'
import { getPrimaryOrgCode, getSubject } from '../selectors/commonSelector'
import { getContextBasedUrl } from '../utils/apiConfigs/utils'
import { encryptData } from './syncQueue'
import storage from '../utils/storage/storage'
import { decryptString, encryptString } from '../utils/cryptoUtil'

export const PUSH_PARTIAL_FORM_DATA_TO_QUEUE = createActionType('PUSH_PARTIAL_FORM_DATA_TO_QUEUE')
export const PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY = createActionType('PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY')

const { ContextProperties: {PRIMARY_ORG_CODE, STUDY_ID, SITE_ID} } = constants

/**
 * Processes partial form data stored in local storage by decrypting, dispatching to queue, and cleaning up
 * @param {Function} dispatch - Redux dispatch function
 */
export const processPartialDataFromStorage = () => async (dispatch) => {
  try {
    const partialEncryptedSvfs = storage.getString(constants.PARTIAL_SUBMISSION_DATA_KEY)
    const partialSvfsString = decryptString(partialEncryptedSvfs)
    const partialSvfs = JSON.parse(partialSvfsString)
    if(!_.isEmpty(partialSvfs)){
      const keys = Object.keys(partialSvfs)
      keys.sort((a, b) => a - b);
      for (const key of keys) {
        const element = partialSvfs[key]
        try {
          await dispatch(dispatchPartialSvf(element))
          delete partialSvfs[key]
        } catch (error) {
          console.error('Failed to process partial data', error)
        }
      }
      if(_.isEmpty(partialSvfs)){
        storage.delete(constants.PARTIAL_SUBMISSION_DATA_KEY)
      } else {
        storage.set(constants.PARTIAL_SUBMISSION_DATA_KEY, encryptString(JSON.stringify(partialSvfs)))
      }
    }
  } catch (error) {
    console.error('Failed to process partial data from storage', error)
  }
}

/**
 * Creates and stores partial SVF data item with metadata
 * @param {Object} svf - Subject visit form data
 * @param {string} subjectId - Subject ID
 * @param {string} completedOn - Completion timestamp
 * @param {string} crfVersionId - CRF version ID
 * @param {string} subjectVisitId - Subject visit ID
 * @param {Object} state - Redux state
 * @returns {Object|null} Created queue item or null if error
 */
const getPartialSvfDataAndStore = (svf, subjectId, completedOn, crfVersionId, subjectVisitId, state) => {
  try {
    const url = getContextBasedUrl(`datasync/${PRIMARY_ORG_CODE}/studies/${STUDY_ID}/crfVersions/${crfVersionId}/sites/${SITE_ID}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms`)
    const userId = getUserId(state)
    const userType = getUserType(state)
    const primaryOrgCode = getPrimaryOrgCode(state)
    const subject = getSubject(state)
    const publicKey = _.get(subject, 'publicKey', null)
    
    const svfId = svf.id
    const svfStatus = svf.status

    const currentItem = {
      url,
      data: svf,
      method: 'POST',
      headers: null,
      isActive: true,
      status: constants.OfflineDataStatus.ACTIVE,
      submittedOn: completedOn,
      attempts: 0,
      userId,
      subjectId,
      userType,
      primaryOrgCode,
      publicKey,
      svfId,
      svfStatus,
      subjectVisitId,
      subject: {
        id: subject.id,
        lastUpdatedDateOn: subject?.lastUpdatedDateOn
      }
    }
    const partialSvfs = {
      [new Date(currentItem?.data?.completedDateTime).valueOf()]: currentItem
    }
    const encryptedVal = encryptString(JSON.stringify(partialSvfs))
    storage.set(constants.PARTIAL_SUBMISSION_DATA_KEY, encryptedVal)
    return currentItem
  } catch (error) {
    console.error('Failed to process partial data', error)
    return null
  }
}

/**
 * Dispatches partial SVF data to the queue
 * @param {Object} currentItem - Queue item to dispatch
 * @param {Function} dispatch - Redux dispatch function
 */
const dispatchPartialSvf = (currentItem) => async (dispatch) => {
  try {
    await dispatch({
      type: PUSH_PARTIAL_FORM_DATA_TO_QUEUE,
      queueItem: currentItem,
      userId: currentItem?.userId,
    })
    const partialEncryptedSvfs = storage.getString(constants.PARTIAL_SUBMISSION_DATA_KEY)
    const partialSvfsString = decryptString(partialEncryptedSvfs)
    const partialSvfs = JSON.parse(partialSvfsString)
    delete partialSvfs[new Date(currentItem?.data?.completedDateTime).valueOf()]
    if(!_.isEmpty(partialSvfs)){
      storage.set(constants.PARTIAL_SUBMISSION_DATA_KEY, encryptString(JSON.stringify(partialSvfs)))
    } else {
      storage.delete(constants.PARTIAL_SUBMISSION_DATA_KEY)
    }
  } catch (error) {
    console.error('Failed to sync the partial data', error)
  }
}

/**
 * Creates and pushes partial form data to the queue
 * @param {Object} svf - Subject visit form data
 * @param {string} subjectId - Subject ID
 * @param {string} completedOn - Completion timestamp
 * @param {string} crfVersionId - CRF version ID
 * @param {string} subjectVisitId - Subject visit ID
 */
export const pushPartialFormDataToQueue =
  (svf, subjectId, completedOn, crfVersionId, subjectVisitId) => (dispatch, getState) => {
    const currentItem = getPartialSvfDataAndStore(svf, subjectId, completedOn, crfVersionId, subjectVisitId, getState())
    if(!_.isEmpty(currentItem) && currentItem?.userId){
      _.delay(() => {
        dispatch(dispatchPartialSvf(currentItem))
      }, 300)
    }
  }

/**
 * Processes the partial data queue by encrypting and dispatching items when device is online
 * @param {Function} dispatch - Redux dispatch function
 * @param {Function} getState - Redux getState function
 */
export const processPartialQueue = () => async (dispatch, getState) => {
  try {
    const syncPool = getState().partialDataQueue
    const userKeys = Object.keys(syncPool)
    for(const uKey of userKeys){
      const subjectSyncQueue = _.get(syncPool, `${uKey}.data`, {})
      const keys = !_.isEmpty(subjectSyncQueue) ? Object.keys(subjectSyncQueue): []
      keys.sort((a, b) => a - b)
      for (const key of keys) {
        const item = subjectSyncQueue[key]
        if (constants.OfflineDataStatus.ACTIVE === item.status) {
          const { aesEncData, rsaEncryptedAesKey } = await encryptData(item?.data, item?.userId, item?.publicKey)
          dispatch({
            type: PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY,
            queueItem: {
              ...item,
              data: aesEncData,
              rsaEncryptedAesKey,
            },
            userId: item?.userId,
            partialDataKey: key
          })
        }
      }
    }
  } catch (error) {
    console.error('Failed to process partial queue', error)
  }
}
