import React from 'react';
import {StyleSheet, Text, View, Dimensions, Platform } from 'react-native';
import { Colors } from '../common/ColorScheme';
import PropTypes from 'prop-types';
import Modal from '../common/Modal/Modal';
import CustomButton from '../common/Button/Button';
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

const getMarginTop = () => {
    if(Platform.OS === 'web') {
        return SCREEN_WIDTH >= 1030 ? 100 : 80;
    }
    return 0;
};

const getHeight = (onMobile) => {
    if(Platform.OS === 'web') {
        if(SCREEN_HEIGHT < 600) {
            return onMobile ? SCREEN_HEIGHT * 0.7 : SCREEN_HEIGHT * 0.5;
        }
        return onMobile ? SCREEN_HEIGHT * 0.5 : SCREEN_HEIGHT * 0.4;
    }
    if(SCREEN_HEIGHT < 700) {
        return SCREEN_HEIGHT * 0.6;
    }
    return SCREEN_HEIGHT * 0.4;
};

const ResponseConfirmation = ({screenProps: { t }, onCancel, onOk, onMobile}) => {
    return (
        <View style={styles.containerStyle}>
            <Modal
                headerText=""
                hideModal={() => {}}
                t={t}
                style={styles.modalContainerStyle}
            >
                <View style={[styles.container, {height: getHeight(onMobile), marginTop: getMarginTop()}]}>
                    <View style={{ flex: 0.2}}>
                        <Text style={styles.headerTextStyle}>
                            {t('Warning')}
                        </Text>
                        <View style={styles.headerLineBreakStyle} />
                    </View>
                    <View style={{ flex: 0.7}}>
                        <View>
                            <Text style={styles.infoText}>{t('QuestionnaireResponseWarningMessage')}</Text>
                        </View>
                        <View style = {{ justifyContent: 'flex-end', flex: 1}}>
                            <Text style={[styles.infoText, { textAlign: 'center'}]}>{t('UpdateResponseText')}</Text>
                        </View>
                    </View>
                    <View style={styles.footerContainer}>
                        <CustomButton type="primary" title={t('NO')} onPress={onCancel} />
                        <CustomButton type="primary" title={t('YES')} onPress={onOk} />
                    </View>
                </View>
            </Modal>
        </View>
    );
};

ResponseConfirmation.propTypes = {
    screenProps: PropTypes.shape({
        t: PropTypes.func.isRequired
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    onMobile: PropTypes.bool.isRequired
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.white,
        alignSelf: 'center',
        padding: 20,
        borderRadius: 10,
        elevation: 10,
        shadowColor: '#000',
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    infoText: {
        fontWeight: "400",
        fontSize: 15,
        lineHeight: 24,
        textAlign: "left",
        fontFamily: 'Inter',
        color: Colors.primaryTextColor,
        flexWrap: 'wrap'
    },
    modalContainerStyle: {
        flex: 1,
        borderRadius: 5,
        width: SCREEN_WIDTH >= 1030 ? 800 * 0.7 : SCREEN_WIDTH * 0.75,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "transparent",
    },
    footerContainer: {
        flexDirection: 'row',
        justifyContent: "center",
        gap: 5
    },
    headerTextStyle: {
        fontSize: 20,
        fontWeight: "500",
        lineHeight: 24,
        fontFamily: 'Inter',
        color: Colors.warningColor,
        textTransform: 'uppercase'
    },
    headerLineBreakStyle: {
        flex: 1,
        borderBottomColor: Colors.borderColor,
        borderBottomWidth: 2,
        marginVertical: 8
    },
    containerStyle: {
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
});

export default ResponseConfirmation;