import { Spinner } from 'native-base'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormConsumer from '../components/form/FormConsumer'
import _ from 'lodash'
import { setPrintToken } from '../utils/secureStorageUtils'
import constants from '../constants/constants'
import { getSessionPool } from '../selectors/session'
import { DATE_FORMAT } from '../utils/dateUtils'
import { getFieldGroupListFormPrint, getFieldsFromFieldGroupListFormPrint, getFormPrintForm, getstudyMetaDataPool } from '../selectors/studyMetaData'
import { getCrfDataPool } from '../selectors/crfData'
import { retrieveFormWithFields } from '../actions/studyMetaData'
import { retrieveCrfData } from '../actions/crfData'
import PropTypes from 'prop-types'
import moment from 'moment'
import { storeModeInSessionStorage } from '../utils/util'

export class FormPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formId: null,
      loading: false,
      fieldId: null,
      svfId: null,
      subjectId: null,
      siteName: null,
      siteId: null,
      recordNo: null,
      selectedVisitId: null,
      clientId: null,
      formLevel: null,
    }
  }
  async componentDidMount() {
    storeModeInSessionStorage("printView")
    const {
      actions,
      screenProps: { t },
    } = this.props
    const formId = this._getParamFromURL('formId')
    const fieldId = this._getParamFromURL('fieldId')
    const svfId = this._getParamFromURL('svfId')
    const subjectId = this._getParamFromURL('subjectId')
    const visitFormOid = this._getParamFromURL('visitFormOid')
    const isTraining = this._getParamFromURL('isTraining')
    const siteName = this._getParamFromURL('siteName')
    const siteId = this._getParamFromURL('siteId')
    const recordNo = this._getParamFromURL('recordNo')
    const selectedVisitId = this._getParamFromURL('selectedVisitId')
    const primaryOrgCode = this._getParamFromURL('primaryOrgCode')
    const studyId = this._getParamFromURL('studyId')
    const jwt = this._getParamFromURL('jwt')
    const clientId = this._getParamFromURL('clientId')
    const formLevel = this._getParamFromURL('formLevel')
    const printLocale = this._getParamFromURL('locale')

    const printOptions = {
      printHeader: this._getParamFromURL('printHeader') === 'true',
      printFooter: this._getParamFromURL('printFooter') === 'true',
      printLayout: this._getParamFromURL('printLayout') || 'mobile',
      primaryOrgCode,
      studyId,
      siteId,
      formId,
      clientId,
      formLevel
    }
    this.setState({
      formId,
      fieldId,
      svfId,
      printOptions,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      selectedVisitId,
      clientId,
      formLevel,
      printLocale
    })
    setPrintToken(jwt);
    await actions.retrieveFormWithFields({
      svfId: svfId,
      formId,
      primaryOrgCode,
      studyId,
      siteId,
      clientId: _.isEqual(formLevel, constants.targetEntityType.GLOBAL) ? "" : clientId,
      formLevel,
      userId: constants.AppType.PRINT
    })
    if(svfId){
      await actions.retrieveCrfData({svfId,primaryOrgCode,studyId,siteId,subjectId,subjectVisitId: selectedVisitId, isPrint: true})
    }
  }

  _getParamFromURL = (param) => {
    console.log(window.location.search)
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }
  render() {
    const {
      formId,
      fieldId,
      svfId,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      selectedVisitId,
      printLocale,
    } = this.state
    const { screenProps, navigation, fieldList, form, formAnswersLoading, formListLoading, formAnswers, scheduleDate, fieldGroupList } =
      this.props
    let printOptions = { ...this.state.printOptions }
     printOptions = {
      ...printOptions,
      formName: form.formName,
    }
    if (!formId || formListLoading || formAnswersLoading) {
      return (
        <View style={{ flex: 1 }}>
          <Spinner color="#4e89ae" />
        </View>
      )
    }

    const selectedSvf = { form: form, status: 'COMPLETED', svfId: svfId }

    if (!fieldList.length) {
      return <Text>This form does not have any fields</Text>
    }

    return (
      <View style={{ flex: 1 }}>
        <FormConsumer
        screenProps={{
          ...screenProps,
          t: (text) => screenProps.t(text, {locale:printLocale})
        }}
          isDeviceOnline={true}
          mode={'printView'}
          navigation={navigation}
          loading={false}
          offlineSaveLoading={false}
          selectedSvf={selectedSvf}
          printOptions={printOptions}
          preview={true}
          previewFieldId={fieldId}
          formId={formId}
          svfId={svfId}
          subjectId={subjectId}
          visitFormOid={visitFormOid}
          isTraining={isTraining}
          siteName={siteName}
          siteId={siteId}
          recordNo={recordNo}
          selectedVisitId={selectedVisitId}
          formAnswers={formAnswers}
          scheduleDate={scheduleDate}
          isSvfUpdated={false}
          fieldList={fieldList}
          fieldGroupList={fieldGroupList}
          userTimezone={moment.tz.guess(true)}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  form: getFormPrintForm(state),
  formListLoading: _.get(getstudyMetaDataPool(state)?.[constants.AppType.PRINT]?.questionnaires, 'loading', false),
  formAnswersLoading: _.get(getCrfDataPool(state)?.[constants.AppType.PRINT], 'loading', false),
  formAnswers: getCrfDataPool(state)?.[constants.AppType.PRINT],
  fieldGroupList: getFieldGroupListFormPrint(state),
  fieldList: getFieldsFromFieldGroupListFormPrint(state),
  scheduleDate: _.get(getSessionPool(state)?.[constants.AppType.PRINT], 'selectedDate', moment().format(DATE_FORMAT)),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveFormWithFields,
      retrieveCrfData,
     
    },
    dispatch
  ),
})


FormPrint.defaultProps = {
  actions: {
    retrieveFormWithFields :()=> null,
    retrieveCrfData:  ()=> null,
  }
}

FormPrint.propTypes = {
  actions: PropTypes.shape({
    retrieveCrfData :PropTypes.func,
    retrieveFormWithFields:PropTypes.func,
  })
} 

export default connect(mapStateToProps, mapDispatchToProps)(FormPrint)
