// import { default as Form } from '../components/FormPreview';
import { Spinner } from 'native-base'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormConsumer from '../components/form/FormConsumer'
import _ from 'lodash'
import { setPreviewToken } from '../utils/secureStorageUtils'
import constants from '../constants/constants'
import { getFormPreviewFieldGroupList, getstudyMetaDataPool, getFieldsFromFieldGroupListFormPreview, getFormPreviewForm } from '../selectors/studyMetaData'
import { DATE_FORMAT } from '../utils/dateUtils'
import moment from 'moment'
import { getCrfDataPool } from '../selectors/crfData'
import PropTypes from 'prop-types'
import { getSessionPool } from '../selectors/session'
import { retrieveFormWithFields } from '../actions/studyMetaData'
import { storeModeInSessionStorage } from '../utils/util'

export class FormPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formId: null,
      fieldId: null,
      previewLoading: true,
      studyId: null,
      siteId: null,
      primaryOrgCode: null,
      clientId: null,
      formLevel: null,
    }
  }
  async componentDidMount() {
    storeModeInSessionStorage("preview")
    const {
      screenProps: { t },
    } = this.props
    const formId = this._getParamFromURL('formId')
    const fieldId = this._getParamFromURL('fieldId')
    const primaryOrgCode = this._getParamFromURL('primaryOrgCode')
    const studyId = this._getParamFromURL('studyId')
    const jwt = this._getParamFromURL('jwt')
    const siteId = this._getParamFromURL('siteId')
    const clientId = this._getParamFromURL('clientId')
    const formLevel = this._getParamFromURL('formLevel')
    this.setState(prevState => ({
      ...prevState,
      formId, fieldId, studyId, siteId, primaryOrgCode, clientId, formLevel
    }), () => this.getFieldsList(formId, primaryOrgCode, studyId, clientId, formLevel))
    
    setPreviewToken(jwt)

  }

  getFieldsList = async (formId,primaryOrgCode, studyId, clientId, formLevel) => {
    const { actions } = this.props
    await actions.retrieveFormWithFields({
      formLevel,
      formId,
      primaryOrgCode,
      studyId,
      clientId: _.isEqual(formLevel, constants.targetEntityType.GLOBAL) ? "" : clientId,
      userId: constants.AppType.PREVIEW
    })
    this.setState({ previewLoading: false })
  }

  _getParamFromURL = (param) => {
    console.log(window.location.search)
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }
  render() {
    const { formId, fieldId, previewLoading } = this.state
    const { screenProps, navigation, fieldList, form, loading, formAnswers, scheduleDate, fieldGroupList } = this.props
    if (!formId || loading || previewLoading) {
      return (
        <View style={{ flex: 1 }}>
          <Spinner color="#4e89ae" />
        </View>
      )
    }

    const selectedSvf = { status: 'COMPLETED', form: form, id: formId }

    if (!fieldList.length) {
      return <Text>This form does not have any fields</Text>
    }
    return (
      <View style={{ flex: 1 }}>
        <FormConsumer
          isDeviceOnline={true}
          mode={'preview'}
          navigation={navigation}
          loading={false}
          offlineSaveLoading={false}
          screenProps={screenProps}
          selectedSvf={selectedSvf}
          preview={true}
          previewFieldId={fieldId}
          formAnswers={formAnswers}
          scheduleDate={scheduleDate}
          isSvfUpdated={false}
          fieldList={fieldList}
          fieldGroupList={fieldGroupList}
          userTimezone={moment.tz.guess(true)}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: getFormPreviewForm(state),
    loading: _.get(getstudyMetaDataPool(state)?.[constants.AppType.PREVIEW]?.questionnaires, 'loading', false),
    formAnswers: getCrfDataPool(state)?.[constants.AppType.PREVIEW],
    fieldGroupList: getFormPreviewFieldGroupList(state),
    fieldList: getFieldsFromFieldGroupListFormPreview(state),
    scheduleDate: _.get(getSessionPool(state)?.[constants.AppType.PREVIEW], 'selectedDate', moment().format(DATE_FORMAT)),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveFormWithFields,
    },
    dispatch
  ),
})



FormPreview.defaultProps = {
  actions: {
    retrieveFormWithFields: ()=> null,
  }
}

FormPreview.propTypes = {
  actions: PropTypes.instanceOf({
    retrieveFormWithFields: PropTypes.func,
  })
} 
export default connect(mapStateToProps, mapDispatchToProps)(FormPreview)
