import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api'
import { getUserId } from '../selectors/user'
import { getStudyData, getUserStudySite } from '../selectors/studyMetaData'
import constants from '../constants/constants'
import { updateDataLoader } from './storeAppStatus'
import { getSubjectCurrentVersion } from './studyMetaData'
const { ContextProperties: {PRIMARY_ORG_CODE} } = constants;

export const UPDATE_SELECTED_SUBJECT = createActionType('UPDATE_SELECTED_SUBJECT')
export const CLEAR_SELECTED_SUBJECT = createActionType('CLEAR_SELECTED_SUBJECT')

//To retrieve subjects for clinician
export const retrieveSubjects = () => async (dispatch, getState) => {
  const site = getUserStudySite(getState())
  const study = getStudyData(getState())
  dispatch(updateDataLoader(true))
  try {
    const res = await api.get(
      `/${PRIMARY_ORG_CODE}/studies/${study.id}/sites/${site.id}/subjects`
    )
    dispatch(updateDataLoader(false))
    return res.data
  } catch (error) {
    dispatch(updateDataLoader(false))
    console.log(error)
    throw error
  }
}

export const updateSubject = (subjectData, userId) => async (dispatch, getState) => {
  if(_.isEmpty(userId)){
    userId = getUserId(getState())
  }
  await dispatch({
    type: UPDATE_SELECTED_SUBJECT,
    subject: subjectData,
    userId,
  })
  await dispatch(getSubjectCurrentVersion(subjectData.id))
}

export const clearSelectedSubject = (userId) => async (dispatch, getState) => {
  if(_.isEmpty(userId)){
    userId = getUserId(getState())
  }
  dispatch({
    type: CLEAR_SELECTED_SUBJECT,
    userId,
  })
}

