import { PUSH_FORM_DATA_TO_QUEUE, processQueue } from '../actions/syncQueue'
import { STORE_APP_STATUS, updateDownloadImageTimerId, updateSyncQueueTimer } from '../actions/storeAppStatus'
import { getDeviceStatus } from '../actions/users'
import _ from 'lodash'
import { startBackGroundJob } from '../utils/backgroundTask/downloadImageUtil'
import { Platform } from 'react-native'
import Constants from 'expo-constants'
import constants from '../constants/constants'
import { GET_META_DATA_SUCCESS, getClickableImagesConfigured, RETRIEVE_FORMS_WITH_FIELDS_SUCCESS, UPDATE_IMAGE_CODES } from '../actions/studyMetaData'
import { processPartialQueue, PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY, PUSH_PARTIAL_FORM_DATA_TO_QUEUE } from '../actions/partialDataQueue'

export const loggerMiddleware = (store) => (next) => (action) => {
  let result = next(action)
  if (
    (action.type === PUSH_FORM_DATA_TO_QUEUE || action.type === PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY) && getDeviceStatus()
  ) {
     store.dispatch(processQueue(false))
  }
  if (
    action.type === PUSH_PARTIAL_FORM_DATA_TO_QUEUE 
  ) {
     store.dispatch(processPartialQueue())
  }
  if (
    action.type === STORE_APP_STATUS && 
    !_.isEmpty(store.getState().syncQueue) && getDeviceStatus()){
    var queueId = setInterval(() => {
      if( !_.isEmpty(store.getState().partialDataQueue)){
        store.dispatch(processPartialQueue())
      }
      store.dispatch(processQueue(true))
    }, 30000);
    store.dispatch(updateSyncQueueTimer(queueId))
  }
  
  if(action.type === RETRIEVE_FORMS_WITH_FIELDS_SUCCESS || (_.isEqual(store.getState()?.appStatus?.appType, constants.AppType.SITESTAFF) && action.type === GET_META_DATA_SUCCESS)){
    store.dispatch(getClickableImagesConfigured())
  }
  
  
  if(action.type === UPDATE_IMAGE_CODES){
    store.dispatch(startBackGroundJob())
    if(Platform.OS == 'web'){
      var queueId = setInterval(() => {
        store.dispatch(startBackGroundJob())
      }, Constants.expoConfig.extra.downloadImageTimer); //5min
      store.dispatch(updateDownloadImageTimerId(queueId))
    }
  }

  return result
}

