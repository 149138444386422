import _ from 'lodash'
import React, { Component } from 'react'
import { Text, View, Dimensions } from 'react-native'
// import { Radio, List } from '@ant-design/react-native';
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from '../form/fieldComponents/SimpleRadioButton/SimpleRadioButton'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import ErrorMessage from '../form/ErrorMessage'
import PropTypes from 'prop-types'
const { width: SCREEN_WIDTH } = Dimensions.get('window')

// const RadioItem = Radio.RadioItem;
const horizontalLayout = {
  //gird layout
  containerStyle: {
    formHorizontal: true,
    animation: false,
  },
  wrapStyle: {
    labelHorizontal: false,
    container: {
      flex: 1,
      marginVertical: 5,
    },
    buttonWrapStyle: {},
  },
  labelStyle: {
    labelHorizontal: false,
    label: { textAlign: 'center' },
  },
  scoreLabelStyle: {
    alignItems: 'center',
  },
}
const verticalLayout = {
  //scroll or step layout
  containerStyle: {
    formHorizontal: false,
    animation: false,
  },
  wrapStyle: {
    labelHorizontal: true,
    container: {},
    buttonWrapStyle: {
      marginLeft: 10,
      marginTop: 10,
    },
  },
  labelStyle: {
    labelHorizontal: true,
    label: {
      margin: 10,
      fontSize: 15,
      flexShrink: 1,
    },
  },
}
export class SingleSelect extends Component {

  changeOption = (option) => {
    this.changeOptionInFieldDecorator(option)
  }

  changeOptionInFieldDecorator = (selectedOption) => {
    const {
      field: { id },
      onValuesChange
    } = this.props
    onValuesChange(id, selectedOption)
  }
  computeStyles = (layout, style, options, fdUISchema) => {
    const optionWidth = 100 / options.length
    if (layout === 'horizontal') {
      const styles = { ...horizontalLayout }
      // styles.wrapStyle.container.width= `${optionWidth}%`
      styles.wrapStyle.container = {
        ...styles.wrapStyle.container,
        marginVertical: 0,
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 5),
        marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 5),
      }
      return styles
    } else {
      let styles = { ...verticalLayout }
      styles.wrapStyle.buttonWrapStyle = {
        ...styles.wrapStyle.buttonWrapStyle,
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 10),
      }
      styles.labelStyle.label = {
        ...styles.labelStyle.label,
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 10),
        marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 10),
      }

      return styles
    }
  }

  renderScoreLabels = (score, selfScored, layout, fdUISchema) => {
    var scoreLabelStyle = {}
    if (layout === 'horizontal') {
      scoreLabelStyle = {
        ...scoreLabelStyle,
        alignSelf: 'center',
      }
    } else {
      scoreLabelStyle = {
        ...scoreLabelStyle,
        alignSelf: 'flex-start',
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 10),
        marginBottom: 0,
        marginLeft: 10,
        marginRight: 0,
      }
    }
    if (selfScored) {
      return <Text style={scoreLabelStyle}>{score}</Text>
    } else {
      return null
    }
  }

  render() {
    const {
      field: {
        dictionary: { options },
        id,
        isRequired,
        fieldGroup,
        uiSchema: fieldUISchema,
      },
      form,
      t,
      style,
      layout,
      commonOptions,
      labelOrder,
      commonOptionsScore,
      disabled,
      fieldAnswer,
      isGridView,
    } = this.props
    const { selfScored, uiSchema } = fieldGroup
    // var radio_props = [
    //   {label: 'Yes', value: 'Yes' },
    //   {label: 'No', value: 'No' }
    // ];
    const radioVals = _.map(options, (op) => ({
      label: op.value,
      value: op.oid,
      score: op.score,
    }))
    const styles = this.computeStyles(layout, style, options, fieldUISchema)
    const crfData = { ...fieldAnswer }
    return (
      <View testID={`${id}-radio-view`} accessible={!disabled}>
        {form.getFieldDecorator(id, {
          initialValue: crfData ? crfData.optionOid : null,
          rules: isRequired && !disabled
        ? [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
          },
        ]
        : [] ,
        })(
          <RadioForm
            formHorizontal={styles.containerStyle.formHorizontal}
            animation={styles.containerStyle.animation}
            testID={`${id}-radio-form`}
            accessible={!disabled}
          >
            {radioVals.map((obj, i) => (
              <RadioButton
                labelHorizontal={styles.wrapStyle.labelHorizontal}
                key={i}
                wrapStyle={styles.wrapStyle.container}
                accessible={!disabled}
                testID={`${id}-radio-option-${i}`}
              >
                {layout !== 'horizontal' &&
                  obj.score !== obj.label &&
                  this.renderScoreLabels(obj.score, selfScored, layout, fieldUISchema)}
                <RadioButtonInput
                  obj={obj}
                  index={i}
                  buttonSize={SCREEN_WIDTH >= 765 ? 20 : 24}
                  innerButtonSize={SCREEN_WIDTH >= 765 ? 12 : 15}
                  buttonInnerColor={'#9155FD'}
                  buttonOuterColor={'#9e9e9e'}
                  buttonWrapStyle={styles.wrapStyle.buttonWrapStyle}
                  onPress={() => this.changeOption(obj.value)}
                  isSelected={crfData?.optionOid === obj.value}
                  disabled={disabled}
                  accessible={!disabled}
                  testID={`${id}-selectable-${i}`}
                />
                {layout === 'horizontal' &&
                  obj.score !== obj.label &&
                  (!commonOptionsScore && labelOrder[0] === 'optionScore'
                    ? this.renderScoreLabels(obj.score, selfScored, layout, uiSchema)
                    : null)}
                {!commonOptions ? (
                  <RadioButtonLabel
                    obj={obj}
                    index={i}
                    labelHorizontal={styles.labelStyle.labelHorizontal}
                    labelWrapStyle={styles.labelStyle.label}
                    onPress={() => (!disabled ? this.changeOption(obj.value) : null)}
                    accessible={!disabled}
                    testID={`${id}-label-${i}`}
                  />
                ) : null}
                {layout === 'horizontal' &&
                  obj.score !== obj.label &&
                  (!commonOptionsScore && labelOrder[0] === 'optionLabel'
                    ? this.renderScoreLabels(obj.score, selfScored, layout, uiSchema)
                    : null)}
              </RadioButton>
            ))}
          </RadioForm>

          //   <List>{options.map((option,index) => (
          //   <RadioItem key={index} checked={selectedOption === option.oid} onChange={() => this.changeOption(option.oid)}>
          //     <Text>{option.value}</Text>
          //   </RadioItem>

          // ))}</List>
        )}
        
        {!isGridView && <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />}
      </View>
    )
  }
}

export class RadioFieldDecorator extends Component {
  state = {}

  render() {
    const {
      fieldComponent,
      field: { crfData, ordinal, id },
      form,
    } = this.props
    // const { getFieldDecorator } = form;
    return this.props.form.getFieldDecorator(`${id}`, {
      initialValue: crfData ? crfData.optionOid : null,
    })(fieldComponent)
  }
}

SingleSelect.defaultProps = {
  field: {},
  fieldAnswer: {},
  form: {},
  t: () => {},
  style: {},
  layout: 'vertical',
  commonOptions: false,
  labelOrder: [],
  commonOptionsScore: false,
  disabled: false,
  isGridView: false,
  onValuesChange: () => {},
};

SingleSelect.propTypes = {
  field: PropTypes.object,
  fieldAnswer: PropTypes.object,
  form: PropTypes.object,
  t: PropTypes.func,
  style: PropTypes.object,
  layout:'horizontal',
  commonOptions: PropTypes.bool,
  labelOrder: PropTypes.array,
  commonOptionsScore: PropTypes.bool,
  disabled: PropTypes.bool,
  isGridView: PropTypes.bool,
  onValuesChange: PropTypes.func,
};

RadioFieldDecorator.defaultProps = {
  fieldComponent: null,
  field: {},
  form: {},
};

RadioFieldDecorator.propTypes = {
  fieldComponent: PropTypes.node,
  field: PropTypes.object,
  form: PropTypes.object,
};
