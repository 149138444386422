
import { Spinner } from 'native-base'
import { createForm } from 'rc-form'
import React, { Component } from 'react'
import {
  AppState,
  Dimensions,
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { bindActionCreators } from 'redux'
import {
  sendPrintData,
  submitForm
} from '../../actions/form'
import { styles } from '../../components/fields/styles'
import { Authorize } from '../../utils/appPrivileges'
import { disableForm, getSvf } from '../../utils/fieldutils/field'
import { Default } from '../../utils/MatchMedia'
import showToast from '../../utils/toast'
import FormInstruction from '../FormInstructionScreen'
import Field from './Field'
import Header from './Header'
import Info from './Info'
import PrintContent from './print/PrintContent'
import Result from './Result'
import StepLayout from './StepLayout'
import { pushFormDataToQueue } from '../../actions/syncQueue'
import { updateFieldAnswer, updateFieldAnswerSuccess } from '../../actions/crfData'
import placeFieldValues from '../../utils/fieldutils/fieldDecorator'
import constants from '../../constants/constants'
import { getDeviceStatus } from '../../actions/users'
import DiaryDisplay from './DiaryDisplay'
import PropTypes from 'prop-types'
import { updateSelectedSvf } from '../../actions/session'
import { updateSvfStatus } from '../../actions/studyMetaData'
import { getPreparedFormItems } from '../../selectors/subjectVisitForm'
import ErrorsOverview from './ErrorsOverview'
import ResponseConfirmation from './ResponseConfirmation'
import { fetchNextFieldByFieldRule } from '../../utils/fieldutils/fieldRule'
import { pushPartialFormDataToQueue } from '../../actions/partialDataQueue'
import FormConstants from './constants';
import _ from 'lodash';

export class FormConsumer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formData: [],
      isInstructionsRead: false,
      previewFieldId: null,
      currentPage: 0,
      errorKeys: [],
      isSubmittTriggered: false,
      responseConfirmation: {
        show: false,
        currentFieldValue: null,
        updatedFieldValue: null,
      },
      currentFieldId: null,
      currentErrorIndex: 0,
      itemHeights: [],
      indexLoaded: -1,
    }
    this.fieldRefs = []
    this.appStateListener = null;
    this.flatListRef = React.createRef()
    this.formRef = React.createRef();
    // Remove ref creation from constructor since we'll attach in render
  }

  printPdf = () => {
    setTimeout(() => {
      this.printElement.click()
    }, 1000)
  }

  getLayoutFields = () => {
    const { onMobile } = this.props
    const { formData } = this.state
    if (!onMobile) {
      return formData
    }
    return _.flatMap(formData?.sections, section => 
      _.chain(section?.data)
        .filter(field => field?.type === 'field')
        .map(field => field)
        .value()
    )
  }
  handleAppStateChange = (nextAppState) => {
    if ( nextAppState === "background" || nextAppState === "inactive") {
      this.saveDraftDataToServer(false)
    }
  }
  async componentDidMount() {
    const {
      selectedSvf,
      preview,
      previewFieldId,
      mode
    } = this.props
    if (preview) {
      this.setState({ previewFieldId: previewFieldId })
      setTimeout(() => {
        console.log(this.fieldRefs[previewFieldId])
        if (this.fieldRefs[previewFieldId] !== undefined) {
          this.fieldRefs[previewFieldId].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 1000)
    }
    this.getFormData(selectedSvf.isFilled, false)
    if (Platform.OS === 'web') {
      window.addEventListener('beforeunload', this.handletabClose)
    }
    if (mode === 'printView') {
      this.printPdf()
    }
   
    if(!preview){
      this.appStateListener = AppState.addEventListener('change', this.handleAppStateChange);
    }
  }

  updateItemHeights = (index, height) => {
      this.setState((prevState) => ({
        ...prevState,
        itemHeights: [...prevState.itemHeights.slice(0, index), height, ...prevState.itemHeights.slice(index + 1)],
      }))
  }
  componentWillUnmount() {
    this.saveDraftDataToServer(false)
    if (Platform.OS === 'web') {
      window.removeEventListener('beforeunload', this.handletabClose)
    }
    if(this.appStateListener){
      this.appStateListener.remove()
    }
  }

  setCurrentPage = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: page,
    }))
  }
  handletabClose = (ev) => {
    const { selectedSvf } = this.props
    if (!disableForm(selectedSvf)) {
      ev.preventDefault()
      ev.returnValue = true
    } else {
      return
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const { fieldGroupList, formAnswers, selectedSvf } = this.props
    const { isInstructionsRead, currentPage } = this.state
    if (
      !_.isEqual(prevProps.fieldGroupList, fieldGroupList) ||
      !_.isEqual(prevProps.formAnswers?.[selectedSvf?.svfId], formAnswers?.[selectedSvf?.svfId]) ||
      !_.isEqual(currentPage, prevState.currentPage) 
    ) {
      this.getFormData(isInstructionsRead, prevState.currentPage < currentPage)
    }
  }



  onValuesChange = (fieldId,fieldValue) => {
    const { fieldList, selectedSvf, formAnswers, form } = this.props
    const currentField = _.find(fieldList, field => _.isEqual(field.id, fieldId));

    const disabledFields = this.getDisabledFields(currentField, fieldList, fieldValue);
    this.setState({
      currentFieldId: fieldId,
    })
    if(!_.isEmpty(disabledFields)){
      const currentFieldValue = formAnswers[selectedSvf.svfId]?.[currentField.fieldOid]?.fieldValue
      form.setFieldsValue({
        [currentField?.id]: currentFieldValue
      })
      _.debounce(() => {
        this.setState({responseConfirmation: {
          show: true,
          updatedFieldValue: fieldValue,
          currentFieldValue,
        }})
      }, 100)()
    } else {
      this.updateFieldValue(currentField, fieldValue)
    }
  }

  getDisabledFields = (currentField, fieldList, fieldValue) => {
    const { formAnswers, selectedSvf } = this.props
    let fieldWithValue = {...currentField};
    if(_.isEmpty(fieldWithValue?.crfData)){
      fieldWithValue.crfData= {
        fieldValue: fieldValue,
        optionOid: fieldValue,
      }
    } else {
      fieldWithValue.crfData.fieldValue = fieldValue;
      fieldWithValue.crfData.optionOid = fieldValue;
    }
    const nextFieldOrdinal = fetchNextFieldByFieldRule(fieldWithValue, fieldList);
    const disabledFields = _.filter(fieldList, field =>  field?.fieldGroup?.id === currentField?.fieldGroup?.id && field.ordinal > currentField.ordinal && field.ordinal < nextFieldOrdinal);
    return _.filter(disabledFields, field => !_.isEmpty(formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.fieldValue));
  }

  updateFieldValue = (currentField, selectedFieldValue) => {
    const {actions, selectedSvf, form } = this.props;
    const { isSubmittTriggered } = this.state;
    const loChanged = {
      [currentField?.fieldOid]: selectedFieldValue
    }
    actions.updateFieldAnswer(loChanged, selectedSvf.svfId)
    form.setFieldsValue({
      [currentField?.id]: selectedFieldValue
    })
    if(isSubmittTriggered){
      form.validateFields([currentField?.id], { force: true })
    }
  }

  onResponseChangeCancel = () => {
    const { currentFieldId, responseConfirmation } = this.state;
    const { currentFieldValue } = responseConfirmation;
    const { form } = this.props;
    form.setFieldsValue({
      [currentFieldId]: currentFieldValue
    })
    this.setState({
      responseConfirmation: {
        show: false,
        updatedFieldValue: null,
        currentFieldValue: null,
      }
    })
  }

  onResponseChangeConfirmation = () => {
    const { currentFieldId, responseConfirmation } = this.state;
    const { updatedFieldValue } = responseConfirmation;
    const { fieldList } = this.props;
    const currentField = _.find(fieldList, field => _.isEqual(field.id, currentFieldId));

    this.updateFieldValue(currentField, updatedFieldValue);
    const disabledFields = this.getDisabledFields(currentField, fieldList, updatedFieldValue);
    _.forEach(disabledFields, f => {
      this.updateFieldValue(f, null);
    })
    // this.validateFields([currentFieldId], { force: true })
    this.setState({
      responseConfirmation: {
        show: false,
        updatedFieldValue: null,
        currentFieldValue: null,
      }
    })
  }

  getFormData = (isInstructionsRead, isNewPage) => {
    const { formContent } = this.props
    this.setState({
      loading: true,
    })
    this.setState({
      formData: formContent,
      loading: false,
      isInstructionsRead,
    })
  }

  /**
   * Scrolls the form to the specified field by key
   * @param {string} key - The key of the field to scroll to
   * @returns {void}
   * 
   * If the target field index is beyond currently loaded items:
   * - Updates indexLoaded state
   * - Scrolls to total height of loaded items
   * - Retries scroll after 500ms delay
   * 
   * Otherwise:
   * - Calculates height up to target index
   * - Scrolls directly to that offset
   */
  scrollToIndex = (key) => {
    const { formData, indexLoaded, itemHeights } = this.state
    const index = formData?.findIndex(item => item.key === key)
    
    if (index === -1 || !this.flatListRef) return

    if (index > indexLoaded) {
      this.setState({ indexLoaded: index })
    }
    if(index > itemHeights.length || (indexLoaded === -1 && index > 20)){
      const totalHeight = itemHeights.reduce((sum, height) => sum + height, 0);
      this.flatListRef.scrollToOffset({
        offset: totalHeight,
        animated: true
      });
      setTimeout(() => {
        this.scrollToIndex(key)
      }, 500)
    } else {
      const heightTillIndex = itemHeights.slice(0, index).reduce((sum, height) => sum + height, 0);
      this.flatListRef.scrollToOffset({
        offset: heightTillIndex,
        animated: true
      });
    }
  }
  
  /**
   * To get cuurent field index which user updated
   * @param {string} action 
   * If action is SUBMIT return 0 to focus first error field
   * Else find the the index 
   * @param {Array} fieldData 
   * @returns 
   */
  getCurrentFieldIndex = (action, fieldData) => {
    const { currentFieldId } = this.state;
    if(action === FormConstants.ERROR_ACTIONS.SUBMIT) {
      return 0;
    }
    const currentFieldIndex = _.findIndex(fieldData, fl => fl.key == currentFieldId)
    const validIndex = currentFieldIndex === -1 ? 0 : currentFieldIndex
    return validIndex
  }


  /**
   * Method to get the errors in scroll layout
   * @returns {Array} Array of field errors from getFieldErrors()
   */
  getScrollLayoutErrors =() => {
    return this.getFieldErrors();
  }

  /**
   * Method to get validation errors for step layout form
   * Filters unfilled required fields to only include those present in the current form step
   * @returns {Array} Array of field errors that are present in current form step
   */
  getStepLayoutErrors =() => {
    const unfilledFields = this.getFieldErrors();
    if(_.isEmpty(unfilledFields)){
      return [];
    }
    const { form } = this.props;
    const values = form.getFieldsValue();
    const keys = Object.keys(values)
    return _.filter(unfilledFields, uf => keys.includes(uf?.id));
  }

  /**
   * Gets validation errors for form fields
   * @returns {Array} Array of fields that have validation errors:
   * - For numeric fields: Returns fields where value is outside min/max range and field is not disabled
   * - For other fields: Returns required fields that are not disabled and have no value
   * Excludes result, label and barcode field types from validation
   */
  getFieldErrors =() => {
    const {fieldList,
      formAnswers,
      selectedSvf
    } = this.props
    const formData = this.getLayoutFields()
    const filteredFields = _.filter(fieldList,(fl) => {
      const field = _.find(formData, (f) =>
            _.isEqual(f?.data?.fieldOid, fl?.fieldOid)
          )
      if(fl.fieldType === 'num'){
        const fieldValue = formAnswers[selectedSvf.svfId]?.[fl.fieldOid]?.fieldValue
        if(!_.isEmpty(fieldValue)){
          return ((Number(fieldValue) < Number(fl?.enDictionary?.range?.min) || Number(fieldValue) > Number(fl?.enDictionary?.range?.max) ) && !field?.data?.disabled)
        }
      }
      return fl.fieldType !== 'result' && fl.fieldType !== 'label' && fl.fieldType !== 'barcode' && fl.isRequired && !field?.data?.disabled && !formAnswers[selectedSvf.svfId]?.[fl.fieldOid]?.fieldValue
    })
    return filteredFields;
  }

/**
 * Spliting the field list based on the action
 * If action is next the field data is splitted from current index + 1 to last
 * If action is previous the field data is splitted from 0 to current index and then reversed
 * @param {Array} fieldData 
 * @param {number} currentFieldIndex 
 * @param {string} action 
 * @returns {Array} Array of fields to compare with error keys
 */
  getSlicedFields = (fieldData, currentFieldIndex, action) => {
    switch (action) {
      case FormConstants.ERROR_ACTIONS.NEXT:
        return _.slice(fieldData, currentFieldIndex + 1)
      case FormConstants.ERROR_ACTIONS.PREVIOUS:
        return _.reverse(_.slice(fieldData, 0, currentFieldIndex))
      default:
        return fieldData
    }
  };

  /**
   * Using fields to verify finding the next field error from the filtered fields
   * If no record found returning first error if the action was previous
   * If no record found returning last error if the action was next
   * @param {Array} fieldsToVerify 
   * @param {Array} filteredFields 
   * @param {string} action 
   * @returns next field
   */
  findNextFieldId = (fieldsToVerify, filteredFields, action) => {
    const formData = this.getLayoutFields()
    let matchingField = _.find(fieldsToVerify, (field) => _.some(filteredFields, { id: field.key }))
    if (_.isEmpty(matchingField)){
      const fallbackField = action === FormConstants.ERROR_ACTIONS.PREVIOUS ? _.first(filteredFields) : _.last(filteredFields)
      matchingField = _.find(formData, (field) => fallbackField.id === field.key)
    } 
    return matchingField?.key
  }
  

  /**
   * Validates form errors and handles scrolling to error fields
   * @param {number} scrollToIndex - Index of field to scroll to in filtered error fields array
   * @param {boolean} isFilled - Whether the form is being submitted as filled/complete
   * If true:
   * - Scrolls to the error field at scrollToIndex if valid index
   * - Sets errorKeys state with filtered error fields
   * - Triggers form validations
   */
  validateErrors = (isFilled, action = FormConstants.ERROR_ACTIONS.SUBMIT) => {
    const { formData } = this.state;
    const fieldData = _.filter(formData, fl => fl.type === 'field')
    const filteredFields = this.getFieldErrors()
    if(!_.isEmpty(filteredFields)){
    let currentFieldIndex = this.getCurrentFieldIndex(action, fieldData)
    const fieldsToVerify = this.getSlicedFields(fieldData, currentFieldIndex, action)
    const nextFieldId = this.findNextFieldId(fieldsToVerify, filteredFields, action)
    const nextFieldIndex = _.findIndex(filteredFields, { id: nextFieldId })
    this.scrollToIndex(nextFieldId)
    this.setState({
      currentFieldId: nextFieldId,
      currentErrorIndex: nextFieldIndex
    })
  }
  
    if (isFilled) {
      this.setState({ errorKeys: filteredFields }, () => {
        this.triggerValidations()
      })
    }
   
  }
  /**
   * Processes form submission based on whether form is being submitted as filled/complete
   * and current online/offline status
   * @param {boolean} isFilled - Whether the form is being submitted as filled/complete
   * If true:
   * - Validates all required fields are filled
   * - If online, submits full form data
   * - If offline or partial submission, saves form data for later sync
   * If false:
   * - Saves partial form data without validation
   */
  processSubmission = (isFilled, triggerValidations = false) => {
    const {
      onMobile
    } = this.props
    this.setState({isSubmittTriggered: triggerValidations})
    const isOnline = getDeviceStatus();
    const unfilledFields = onMobile ? this.getStepLayoutErrors() : this.getScrollLayoutErrors();
    if(!_.isEmpty(unfilledFields) && isFilled && !onMobile) {
      this.validateErrors(isFilled, FormConstants.ERROR_ACTIONS.SUBMIT);
    } else if(!_.isEmpty(unfilledFields) && isFilled && triggerValidations) {
      this.triggerValidations()
    } else if(_.isEmpty(unfilledFields) && isFilled && isOnline){
        this.submitFullData(isFilled);
    } else if(_.isEmpty(unfilledFields)){
        this.submitPartialData(isFilled);
    }
    
  }
  triggerValidations =() =>{
    const {form} = this.props
    form.validateFields()
  }

 
  /**
   * Submits complete form data when online
   * @param {boolean} isFilled - Whether form is being submitted as filled/complete
   * @returns {Promise<void>} 
   */
  submitFullData = async (isFilled) => {
    const {
      subject,
      updateOfflineSaveLoading,
    } = this.props

    this.setState({isSubmittTriggered: false, errorKeys: []})
    updateOfflineSaveLoading(true)

    try {
      const svf = this.prepareSvfData(isFilled)
      await this.handleFormSubmissionOnline(svf, subject.id, isFilled)
    } finally {
      updateOfflineSaveLoading(false)
    }
  }

  /**
   * Submits partial form data when offline or form is not completely filled
   * @param {boolean} isFilled - Whether form is being submitted as filled/complete
   * @returns {void}
   */
  submitPartialData = (isFilled) => {
    const {
      subject,
      updateOfflineSaveLoading,
    } = this.props
    updateOfflineSaveLoading(true)
    this.setState({isSubmittTriggered: false, errorKeys: []})
    try {
      const svf = this.prepareSvfData(isFilled)
      if(!_.isEmpty(svf?.crfData)){
        this.handleFormSubmissionOffline(svf, subject.id, isFilled) 
      } else {
        actions.updateSelectedSvf(false)
      }
    } finally {
      updateOfflineSaveLoading(false)
    }
  }

  
  prepareSvfData = (isFilled) => {
    const { selectedSvf, subjectVisitId, userTimezone, formAnswers, fieldList, subject } = this.props
    let crfData = []
    const filteredFields = _.filter(
      fieldList,
      (fl) => fl.fieldType !== 'result' && fl.fieldType !== 'label'
    )
    _.forEach(filteredFields, (field) => {
      if (formAnswers?.[selectedSvf.svfId]?.[field.fieldOid]?.isUpdated) {
        let loCrfdata = placeFieldValues(
          formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.fieldValue,
          formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.optionOid,
          field,
          selectedSvf.svfId,
          selectedSvf.crfVersionId
        )
        loCrfdata = { ...loCrfdata, locale: subject?.locale, submissionTs:  formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.submissionTs }
        crfData.push(
          loCrfdata
        )
      }
    })
    const svf = getSvf(selectedSvf, subjectVisitId, userTimezone, crfData, isFilled)
    svf.locale = subject?.locale
    return svf
  }

  handleFormSubmissionOffline = (svf, subjectId, isFilled) => {
    const { actions, navigation, screenProps: { t } } = this.props
    if(!isFilled){
      actions.pushPartialFormDataToQueue(svf, subjectId, svf.completedDateTime, svf?.crfVersion?.id, svf?.subjectVisit?.id)
    } else {
      actions.pushFormDataToQueue(svf, subjectId, svf.completedDateTime, svf?.crfVersion?.id, svf?.subjectVisit?.id)
    }
    actions.updateSvfStatus(
      svf.id,
      svf.subjectVisit?.id,
      constants.OfflineDataStatus.IN_PROGRESS,
      svf.completedDateTime,
      svf.status
    )
    actions.updateSelectedSvf(false)
    if (isFilled) {
      setTimeout(() => {
        showToast(t('SavedMessage'), 'success', 3000)
        navigation.goBack()
      }, 3000)
    }
  }

  handleFormSubmissionOnline = async (svf, subjectId, isFilled) => {
    const { actions, updateOfflineSaveLoading, navigation, screenProps: { t } } = this.props
    try {
      const res = await actions.submitForm(svf.crfVersion?.id, svf.subjectVisit?.id, subjectId, svf)
      if(res.status == 200 || res.status == 201) {
        actions.updateSelectedSvf(false)
        actions.updateFieldAnswerSuccess(svf.svfId)
      }
      if (res.status == 200 || res.status == 201 && isFilled) {
        actions.updateSvfStatus(svf.id, svf.subjectVisit?.id, constants.OfflineDataStatus.SUCCESS, svf.completedDateTime, 'COMPLETED')
        setTimeout(() => {
          showToast(t('SavedMessage'), 'success', 3000)
          navigation.goBack()
        }, 1000)
      }
    } catch (error) {
      console.log(error)
      updateOfflineSaveLoading(false)
      if (isFilled) {
        showToast(t('FailedMessage'), 'error', 3000)
      }
    }
  }

  renderFormItem = (item, footerPageY) => {
    const { screenProps, selectedSvf, form, formAnswers, preview, mode, userTimezone } = this.props
    const { t, locale } = screenProps
    const { isInstructionsRead } = this.state
    switch (item.type) {
      case 'label':
        return <Info title={item.data} isFooter={false} />
      case 'instruction':
        return (
          <FormInstruction
            screenProps={screenProps}
            onNext={() => this.getFormData(true)}
            showOkButton={!isInstructionsRead}
            instruction={item.data}
          />
        )
        break
      case 'field': {
        const fieldAnswer = formAnswers?.[selectedSvf?.svfId]?.[item?.data?.fieldOid] || {}
        return (
          <Field
            {...item}
            t={t}
            form={form}
            selectedSvfId={selectedSvf.svfId}
            fieldAnswer={fieldAnswer}
            mode={mode === 'printView' ? mode : (preview ? 'preview' : '')}
            footerPageY={footerPageY}
            subjectTimezone={userTimezone}
            locale={locale}
            onValuesChange = {this.onValuesChange}
          />
        )
        break
      }
      case 'divider': {
        return (
          <View
            style={{
              marginHorizontal: 10,
              marginVertical: 3,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: 'white',
            }}
          ></View>
        )
      }
      case 'result':
        return <Result {...item} t={t}/>
      case 'header':
        return (
          <Header
            options={item.data}
            uiSchema={item.uiSchema}
            selfScored={item.selfScored}
          />
        )
      case 'footer':
        return <Info title={item.data} isFooter={true} />
      default:
        return null
        break
    }
  }


  renderItem = ({ item, index }) => {
    const { onMobile } = this.props;
    if (!onMobile) {
      return (
        <View onLayout={(e) => this.updateItemHeights(index, e.nativeEvent.layout?.height)}>
          <div ref={(elem) => (this.fieldRefs[item.key] = elem)}>{this.renderFormItem(item, null, index)}</div>
        </View>

      )
    }
    if (Platform.OS === 'web') {
      return (
        <div ref={(elem) => (this.fieldRefs[item.key] = elem)}>{this.renderFormItem(item)}</div>
      )
    }
    return <View>{this.renderFormItem(item)}</View>
  }

  handlePrint = async (target) => {
    const {
      printOptions,
      formId,
      svfId,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      navigation,
      selectedVisitId,
    } = this.props
    let data = target.contentWindow.document.documentElement.outerHTML
    const printRequest = {
      formId: formId,
      content: '<!DOCTYPE html>' + data,
      header: printOptions.printHeader,
      footer: printOptions.printFooter,
      layout: printOptions.printLayout,
      svfId: svfId,
      subjectId: subjectId,
      visitFormOid: visitFormOid,
      training: isTraining,
      siteName: siteName,
      siteId:
        siteId !== 'null' && siteId !== null && siteId !== '' && siteId !== undefined
          ? siteId
          : null,
      recordNo: recordNo,
      selectedSubjectVisitId:
        selectedVisitId !== 'null' &&
          selectedVisitId !== null &&
          selectedVisitId !== '' &&
          selectedVisitId !== undefined
          ? selectedVisitId
          : null,
      primaryOrgCode: printOptions.primaryOrgCode,
      studyId: printOptions.studyId
    }
    const URL = await sendPrintData(printRequest)
    if (URL) {
      window.location.href = URL
    } else {
      navigation.navigate('PrintFail')
    }
  }

  disableSubmitButton = () => {
    const { selectedSvf } = this.props
    return disableForm(selectedSvf)
  }

  saveDraftDataToServer = (triggerValidations = false) => {
    const { isSvfUpdated, selectedSvf, actions } = this.props
    if (isSvfUpdated && !disableForm(selectedSvf)) {
      try {
        this.processSubmission(false, triggerValidations)
      } catch (error) {
        console.error("Failed to sync the data", error)
      }

    } else {
      actions.updateSelectedSvf(false)
    }
  }

  render() {
    const { loading, formData, currentPage, errorKeys,isSubmittTriggered, currentErrorIndex, responseConfirmation } = this.state
    const {
      selectedSvf,
      mode,
      screenProps,
      printOptions,
      form,
      fieldList,
      preview,
      onMobile
    } = this.props
    const { t } = screenProps
    const { training } = selectedSvf
    if (loading) {
      return <Spinner color="#4e89ae" />
    }
    return (
      <View style={{ flex: 1, paddingHorizontal: 5 }} ref = {this.formRef}>
      {
        responseConfirmation?.show && <ResponseConfirmation 
        screenProps = {screenProps}
        onCancel = {this.onResponseChangeCancel}
        onOk = {this.onResponseChangeConfirmation}
        onMobile = {onMobile}
        />
      }
        {mode === 'printView' ? (
          <>
            <View
              style={{
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: Dimensions.get('window').height,
              }}
            >
              <Spinner color="#4e89ae" />
              <Text>Generating pdf...</Text>
            </View>
            <View style={{ visibility: 'hidden' }}>
              <ReactToPrint
                print={this.handlePrint}
                trigger={() => {
                  return (
                    <a
                      ref={(link) => (this.printElement = link)}
                      href="#"
                      style={{ display: 'none', fontSize: 20, color: 'black' }}
                    >
                      Print this out!
                    </a>
                  )
                }}
                pageStyle={
                  this.props.svfId
                    ? '@page { size: auto; margin: 150px 0px 100px 0px; } tr { page-break-inside: avoid;  } .gridHeader { page-break-inside: avoid;  }'
                    : '@page { size: auto; margin: 105px 0px 100px 0px; } tr { page-break-inside: avoid;  } .gridHeader { page-break-inside: avoid;  }'
                }
                content={() => this.componentRef}
              />
              <PrintContent
                ref={(el) => (this.componentRef = el)}
                data={formData}
                renderItem={this.renderItem}
                printOptions={printOptions}
              ></PrintContent>
            </View>
          </>
        ) : (
          <>
            <DiaryDisplay preview={preview} t={t}>
              { onMobile ? (
                <StepLayout
                form={form}
                selectedSvf={selectedSvf}
                formData={formData}
                renderFormItem={this.renderFormItem}
                handleSubmit={(isFullySubmitted, triggerValidations = false) => {
                  if(!isFullySubmitted){
                    this.saveDraftDataToServer(triggerValidations)
                  } else {
                    this.processSubmission(isFullySubmitted, triggerValidations)
                  }
                }}
                disableSubmitButton={this.disableSubmitButton}
                training={training}
                t={t}
                fieldList={fieldList}
                setCurrentPage={this.setCurrentPage}
                currentPage={currentPage}
                getErrors={this.getStepLayoutErrors}
              /> ) : (
              <React.Fragment>
                <FlatList
                  data={formData}
                  renderItem={this.renderItem}
                  keyExtractor={(item) => item.key}
                  ref={(ref) => { this.flatListRef = ref; }}
                  onScroll={() => isSubmittTriggered ? form.validateFields() : {}}
                  initialNumToRender={20}
                  onEndReachedThreshold={0} 
                />
                {!_.isEmpty(errorKeys) &&
                <ErrorsOverview validateErrors={this.validateErrors} t={t} errorKeys = {errorKeys} currentErrorIndex = {currentErrorIndex}/>
                }
              <Authorize hasAnyGrant={["SUBMIT_FORM", "VIEW_SUBJECT"]}>
                <Default>
                  <View style={[styles.submitButtonContainer]}>
                    {this.disableSubmitButton() ? (
                      <Text />
                    ) : (
                      <TouchableOpacity
                        style={styles.submitButton}
                        onPress={() => this.processSubmission(true, true)}
                      >
                        <Text
                          style={{
                            fontFamily: 'Inter',
                            alignSelf: 'center',
                            color: '#fff',
                            textTransform: 'uppercase',
                          }}
                        >
                          {training ? t('SubmitTraining') : t('Submit')}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </Default>
              </Authorize>
              </React.Fragment>
              )}
            </DiaryDisplay>
          </>
        )}
      </View>
    )
  }
}

FormConsumer.defaultProps = {
  footerPageY: 0,
  updateOfflineSaveLoading: () => null,
  printOptions: {},
  preview: false, 
}

FormConsumer.propTypes = {
  form: PropTypes.object,
  onMobile: PropTypes.bool.isRequired,
  selectedSvf: PropTypes.instanceOf(Object).isRequired,
  fieldGroupList: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired, 
  userTimezone: PropTypes.string.isRequired, 
  formAnswers: PropTypes.array.isRequired,
  footerPageY: PropTypes.number,
  subjectVisitId: PropTypes.string,
  screenProps: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.string,
  }),
  subject: PropTypes.shape({
    locale: PropTypes.string,
    id: PropTypes.string,
  }),
  isSvfUpdated: PropTypes.bool,
  fieldList: PropTypes.array,
  actions: PropTypes.shape({
    updateSelectedSvf: PropTypes.func,
    pushFormDataToQueue: PropTypes.func,
    updateFieldAnswer: PropTypes.func,
    updateSvfStatus: PropTypes.func,
    submitForm: PropTypes.func,
    pushPartialFormDataToQueue: PropTypes.func,
    updateFieldAnswerSuccess: PropTypes.func,
  }),
  formContent: PropTypes.arrayOf(Object),
  updateOfflineSaveLoading: PropTypes.func,
  printOptions: PropTypes.object,
  preview: PropTypes.bool,
} 

const mapStateToProps = (state) => ({
  onMobile: state?.appStatus?.onMobile,
  formContent: getPreparedFormItems(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateSelectedSvf,
      pushFormDataToQueue,
      updateFieldAnswer,
      updateSvfStatus,
      submitForm,
      updateFieldAnswerSuccess,
      pushPartialFormDataToQueue,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  createForm({
    onFieldsChange: (props, changed, all) => {
      props.actions.updateSelectedSvf(true)
    },
  })(FormConsumer)
)