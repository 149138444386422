import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';
import PropTypes from 'prop-types';
import { Colors } from '../ColorScheme';

const buttonTypeStyles = {
    primary: {
        color: Colors.white,
        backgroundColor: Colors.primaryColor
    },
    default: {
        color: Colors.primaryColor,
        backgroundColor: Colors.white
    },
    danger: {
        color: Colors.white,
        backgroundColor: Colors.red
    }
}

const CustomButton = (props) => {
    const { styles = {}, type = 'primary', textStyle = {}, title = '', onPress } = props;
    const typeSpecificStyles = buttonTypeStyles[type];
    return (
        <TouchableOpacity 
            style = {[buttonStyles.buttonContainer, {backgroundColor: typeSpecificStyles.backgroundColor},{styles}]} 
            onPress={onPress}
        >
            <Text style = {[buttonStyles.buttonTextStyle, {color: typeSpecificStyles.color}, {textStyle}]}>{title}</Text>
        </TouchableOpacity>
    )
}

CustomButton.propTypes = {
    styles: PropTypes.object,
    type: PropTypes.oneOf(['primary', 'default', 'danger']),
    textStyle: PropTypes.object,
    title: PropTypes.string,
    onPress: PropTypes.func
};

CustomButton.defaultProps = {
    styles: {},
    type: 'primary',
    textStyle: {},
    title: '',
    onPress: () => {}
};

const buttonStyles = StyleSheet.create({
    buttonContainer: {
        borderRadius: 5,
        padding: 10,
        alignItems: 'center',
        justifyContent: "center",
        marginTop: 15
    },
    buttonTextStyle: {
        color: Colors.white,
        fontSize: 18,
        fontFamily: 'Inter',
        fontWeight: "500",
    }
})

export default CustomButton