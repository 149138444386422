import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import CustomTextInput from '../common/CustomTextInput'
import PropTypes from 'prop-types'
import {Colors} from '../common/ColorScheme'
import ErrorMessage from '../form/ErrorMessage'

export class NumericInput extends Component {
  state = {}

  changeFieldValue = (value) => {
    const {
      field: { id }, onValuesChange
    } = this.props
    onValuesChange(id, value.replace(/\D/g, ''))
  }

  validateNumberRange = (rule, value, callBack) => {
    const {
      field: {
        dictionary: { range },
      },
      t,
    } = this.props
    if (Number(value) < Number(range.min) || Number(value) > Number(range.max)) {
      callBack(`${t('NumberValidate1')} ${range.min} ${t('NumberValidate2')} ${range.max}.`)
    }
    callBack()
  }

  render() {

    const {
      field: {
        dictionary: { range },
        id,
        isRequired,
        uiSchema: fdUISchema,
      },
      t,
      disabled,
      fieldAnswer,
      form,
    } = this.props
    const crfData = { ...fieldAnswer }

    const marginTop = getFieldUISchemaData(fdUISchema, 'options', 'top', 0)
    const marginBottom = getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0)

    return (
      <View
        style={[styles.container, { marginTop, marginBottom }]}
      >
        {this.props.form.getFieldDecorator(`${id}`, {
          initialValue: crfData ? crfData.fieldValue : null,
          rules: [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
          },
          {
            validator: this.validateNumberRange,
          },
        ]
        })(
          <CustomTextInput
            keyboardType="numeric"
            placeholder={t('EnterHere')}
            onChangeText={this.changeFieldValue}
            editable={!disabled}
            style={styles.input}
            accessible={!disabled}
            testID={`${id}-numeric-input`}
          />
        )}
          <ErrorMessage id={id} isRequired={true} disabled={disabled} form={form} />
      </View>
    )
  }
}

NumericInput.defaultProps = {
  disabled: false,
  fieldAnswer: {},
  onValuesChange: () => {},
}

NumericInput.propTypes = {
  form: PropTypes.object.isRequired, 
  field: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, 
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.object,
  onValuesChange: PropTypes.func,
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  input: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderWidth: 2,
    borderColor: Colors.borderColor
  },
  errorText: {
    paddingLeft: 15,
    paddingVertical: 7,
    color: Colors.red,
  },
})
