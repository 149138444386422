import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { backgroundColor } from './NavigationScreens'
import { retrieveFAQs } from '../actions/faq'
import Faq from '../components/Faq'
import { ActivityIndicator, View } from 'react-native'
import { getStudyData } from '../selectors/studyMetaData'
import { getSubject } from '../selectors/commonSelector'
import { setCurrentScreen } from '../actions/session'
import PropTypes from 'prop-types'
class FaqScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      faqs: [],
      loading: false,
    }
  }

  componentDidMount() {
    const { setCurrentScreen } = this.props

    setCurrentScreen('')

    this.getFaqs()
  }


  getFaqs = async () => {
    const {
      retrieveFAQs: fetchFaqs,
      subject,
      screenProps: { t },
      study,
    } = this.props
    const studyId = study.id
    if (studyId) {
      this.setState({loading: true})
      const faqs = await fetchFaqs(studyId, subject, t)
      this.setState({faqs, loading: false})
    }
  }

  render() {
    const { navigation,  screenProps } = this.props
    const {loading, faqs} = this.state
    return (
      <View style={{ flex: 1 }}>
        {/* <PTRView onRefresh={this.getFaqs} delay={0}> */}
        {loading ? (
          <View
            style={{
              position: 'absolute',
              justifyContent: 'center',
              alignSelf: 'center',
              alignContent: 'center',
              height: '100%',
            }}
          >
            <ActivityIndicator
              size="large"
              color={backgroundColor}
              overlayColor="rgba(0, 0, 0, 0.07)"
              style={{ alignSelf: 'center' }}
            />
          </View>
        ) : (
          <Faq
            navigation={navigation}
            faqs={faqs || []}
            loading={loading}
            screenProps={screenProps}
          />
        )}
        {/* // </PTRView> */}
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  subject: getSubject(state),
  study: getStudyData(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveFAQs,
      setCurrentScreen,
    },
    dispatch
  )

 
FaqScreen.defaultProps = {
  screenProps: {},
  navigation: {}
}

FaqScreen.propTypes = {
 screenProps: PropTypes.instanceOf(Object),
 navigation: PropTypes.instanceOf(Object),
}  
export default connect(mapStateToProps, mapDispatchToProps)(FaqScreen)
