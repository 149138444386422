import React, { useMemo } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { alignments } from './alignments'
import _ from 'lodash'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import ShowValue from './ShowValue'
import constants from '../../constants/constants'
import { removePercentageFromValue } from '../../utils/util'
import { getScaleProperties, calculateFontSize } from './utils'
import VerticalScaleContainerWrapper from './NRS/VerticalScaleContainerWrapper'
import HorizontalScaleContainerWrapper from './NRS/HorizontalScaleContainerWrapper'
const {SCALE_POSITION} = constants

  const NumericRatingScale = (props) => 
    useMemo(() => {
      const { orientation, value, isPercentage, fdUISchema,  showValueConfig: {showValue, labelText},  scaleVerticalAlignment, id, onNumberChange, start, end, step, options, disabled, onMobile} = props
      const percentageSymbol = isPercentage ? '%' : ''
      const fieldValue =  removePercentageFromValue(value)!=null ?Number(removePercentageFromValue(value)): null
      const scaleProps = getScaleProperties(start, end, step, orientation, onMobile)
      const {widthOfBox} = scaleProps
      const largestNumber = Math.floor(end/step)*step
      const sym = isPercentage?'%':''
      const fontSize = calculateFontSize(10, widthOfBox, _.toString(largestNumber)+sym, 0.6)
  
      return (
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
            marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
          }}
        >
          {showValue && (
            <ShowValue 
              labelText={labelText}
              value= { _.isNumber(fieldValue) ? `${fieldValue}${percentageSymbol}` : ''}
            />
          )}
          
            {_.isEqual(_.toUpper(orientation), _.toUpper(alignments.HORIZONTAL)) && (
                <HorizontalScaleContainerWrapper 
                      start={start}
                      end={end}
                      step={step}
                      value={fieldValue}
                      disabled={disabled}
                      id={id}
                      isPercentage={isPercentage}
                      onNumberChange={onNumberChange}
                      options={options}
                      orientation={orientation}
                      fontSize={fontSize}
                      scaleProps={scaleProps}
              />
            )}
  
            {_.isEqual(_.toUpper(orientation), _.toUpper(alignments.VERTICAL)) && (
                    <VerticalScaleContainerWrapper 
                      scaleVerticalAlignment={scaleVerticalAlignment}
                      start={start}
                      end={end}
                      step={step}
                      value={fieldValue}
                      disabled={disabled}
                      id={id}
                      isPercentage={isPercentage}
                      onNumberChange={onNumberChange}
                      options={options}
                      orientation={orientation}
                      fontSize={fontSize}
                      scaleProps={scaleProps}
                    />
            )}
         
          
        </View>
      )
    }, [props.value, props.disabled])
    

NumericRatingScale.defaultProps = {
  isPercentage: false,
  start: 0,
  end: 10,
  step: 1,
  value: null,
  onNumberChange: () => null,
  orientation: 'Horizontal',
  disabled: false,
  options: [],
  id: null,
  fdUISchema: {},
  scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE,
  showValueConfig: {
    showValue: false, 
    labelText: null, 
  }
}

NumericRatingScale.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onNumberChange: PropTypes.func,
  orientation: PropTypes.string,
  options: PropTypes.arrayOf(Object),
  isPercentage: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  scaleVerticalAlignment: PropTypes.string,
  fdUISchema: PropTypes.instanceOf(Object),
  showValueConfig: PropTypes.shape({
    showValue: PropTypes.bool,
    labelText: PropTypes.string,
}),
}

export default NumericRatingScale
