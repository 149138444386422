import {
    PUSH_PARTIAL_FORM_DATA_TO_QUEUE,
    PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY
  } from '../actions/partialDataQueue'
  import {
    STORE_PARTIAL_DATA_QUEUE
  } from '../actions/syncQueue'
  import initialState from '../store/initialState'
  import _ from 'lodash'
  
  export default (state = initialState.partialDataQueue, action) => {
    switch (action.type) {
      case PUSH_PARTIAL_FORM_DATA_TO_QUEUE:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            data: {
              ...state?.[action.userId]?.data,
              [new Date().valueOf()]: {
                ...action.queueItem, 
              }
            }
          }
        }
      case PUSH_FORM_DATA_TO_QUEUE_AND_REMOVE_KEY: {
        delete state[action.userId].data?.[action.partialDataKey]
        if(_.isEmpty(state[action.userId].data)){
          delete state[action.userId]
        }
        return {
          ...state,
        }
      }
      case STORE_PARTIAL_DATA_QUEUE:
        return {
          ...state,
          ...action.data,
        }
      default:
        return state
    }
  }
  