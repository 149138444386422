import React from 'react'
import CustomTextInput from '../../common/CustomTextInput'
import PropTypes from 'prop-types'

export const TextInput = React.memo(({ t, disabled, changeFieldValue, value }) => {
  return (
    <CustomTextInput
      placeholder={t('EnterHere')}
      onChangeText={changeFieldValue} 
      editable={!disabled}
      value={value}
      style={{
        paddingVertical: 2,
        paddingHorizontal: 5,
        borderBottomWidth: 2,
        borderBottomColor: '#cccccc',
      }}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.disabled === nextProps.disabled && prevProps.value === nextProps.value;
})

TextInput.defaultProps = {
  t: (key) => key,
  disabled: false,
  changeFieldValue: () => {},
  value: null,
}

TextInput.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  changeFieldValue: PropTypes.func,
  value: null,
}
