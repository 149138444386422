import { NavigationActions } from 'react-navigation'

let _navigator

function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef
}

function navigate(routeName, params) {
  _navigator.dispatch(
    NavigationActions.navigate({
      routeName,
      params,
    })
  )
}

function getCurrentRoute() {
  if (!_navigator?.state) {
    return null
  }
  const route = _navigator.state.nav
  if (!route) {
    return null
  }
  return route.routes[route.index].routeName
}




export default {
  navigate,
  setTopLevelNavigator,
  getCurrentRoute
}
