import _ from 'lodash'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import RenderHtml from 'react-native-render-html'
import { getFieldComponent, getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import { setFormData } from '../../utils/secureStorageUtils'
import { generatePercentage } from '../../utils/util'
import PropTypes from 'prop-types'
import fieldTypes from '../../constants/fieldTypes'
import constants from '../../constants/constants'
import ErrorMessage from '../form/ErrorMessage'

const {excludedLocalizationKeys} = constants
const boldStyle = { fontWeight: '500' }
const italicStyle = { fontStyle: 'italic' }
const underlineStyle = { textDecorationLine: 'underline' }
const strikethroughStyle = { textDecorationLine: 'line-through' }
const tagsStyles = {
  b: boldStyle,
  strong: boldStyle,
  i: italicStyle,
  em: italicStyle,
  ins: underlineStyle,
  s: strikethroughStyle,
  strike: strikethroughStyle,
  a: {
    fontWeight: '500',
    color: '#007AFF',
  },
  h1: { fontWeight: '500', fontSize: 36 },
  h2: { fontWeight: '500', fontSize: 30 },
  h3: { fontWeight: '500', fontSize: 24 },
  h4: { fontWeight: '500', fontSize: 18 },
  h5: { fontWeight: '500', fontSize: 14 },
  h6: { fontWeight: '500', fontSize: 12 },
  sub: { fontSize: 12, lineHeight: 17 },
  sup: { fontSize: 12, lineHeight: 50 },
  p: { fontSize: 22 },
}
const defaultSchema = {
  layout: 'scroll',
  styles: {
    field: {
      containerBorder: false,
      containerWidth: '50%',
    },
    options: {
      containerBorder: false,
      direction: 'vertical',
      labelPosition: 'right',
      containerWidth: '50%',
    },
  },
}
class Field extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldComponent: null,
      uiSchema: {},
    }
  }

  componentDidMount() {
    const { form, subjectTimezone, t, data, mode, locale } = this.props;
    const { fieldGroup } = data;
    const uiSchema = fieldGroup.uiSchema;
    let loData = { ...data };
  
    if (data.dictionary) {
      loData.dictionary = this.getLocalizedDictionary(data);
    }
  
    const field = getFieldComponent(loData, form, t, subjectTimezone, uiSchema, mode, locale);
    this.setState({ fieldComponent: field, uiSchema });
  }
  
  getLocalizedDictionary(data) {
    const dictionaryKeys = _.isEmpty(data?.enDictionary) ? [] : Object.keys(data?.enDictionary);
    const { dictionary, enDictionary } = data;
    let loDictionary = {};
  
    dictionaryKeys.forEach(dictionaryKey => {
      if (!_.includes(excludedLocalizationKeys, dictionaryKey)) {
        loDictionary[dictionaryKey] = this.getLocalizedValue(dictionaryKey, dictionary, enDictionary);
      } else {
        loDictionary[dictionaryKey] = enDictionary?.[dictionaryKey];
      }
    });
  
    return loDictionary;
  }

  getLocalizedValue(key, dictionary, enDictionary) {
    if (key === 'map') {
      return this.localizeMap(dictionary.map, enDictionary.map);
    } else if (key === 'options') {
      return this.localizeOptions(dictionary.options, enDictionary.options);
    }
    return dictionary?.[key];
  }
  
  localizeMap(dictionaryMap, enMap) {
    const targetOptions = _.map(enMap.areas,(area) => ({
      ...area,
      value: _.find(dictionaryMap.areas, (da) => {
        return da.oid === area.oid
      })?.value || area.value
    }));
    return { areas: targetOptions };
  }
  
  localizeOptions(dictionaryOptions, enOptions) {
    return _.map(enOptions,(option) => ({
      ...option,
      value: _.find(dictionaryOptions, (opt) => {
        return opt.oid === option.oid
      })?.value || option.value
    }));
  }

  storeDraft = () => {
    const { form, selectedSvfId } = this.props
    const values = form.getFieldsValue()

    setFormData(selectedSvfId, values)
  }

  renderInstruction = (field) => {
    const {
      data: { uiSchema: fdUISchema },
    } = this.props
    if (field.instruction) {
      return (
        <View
          style={{
            marginTop: getFieldUISchemaData(fdUISchema, 'fieldInstruction', 'top', 0),
            marginBottom: getFieldUISchemaData(fdUISchema, 'fieldInstruction', 'bottom', 0),
          }}
        >
          <RenderHtml source={{ html: field.instruction }} />
        </View>
      )
    }
    return <Text></Text>
  }

  renderScrollLayout = () => {
    const { data, fieldAnswer, footerPageY, onValuesChange } = this.props
    const { disabled } = data
    const { fieldComponent, uiSchema } = this.state
    const {
      data: { uiSchema: fdUISchema },
    } = this.props
    return (
      <React.Fragment>
        {/* <NavigationEvents onWillBlur={() => this.storeDraft()} /> */}
        <View style={componentStyles.scrollLayout}>
          <View
            style={{
              marginTop: getFieldUISchemaData(fdUISchema, 'fieldName', 'top', 0),
              marginBottom: getFieldUISchemaData(fdUISchema, 'fieldName', 'bottom', 0),
            }}
          >
            <RenderHtml source={{ html: data.fieldName }} />
          </View>
          {(data.location === 'ABOVE' || data.location === null) && this.renderInstruction(data)}
          <View>
            {fieldComponent && (
              <fieldComponent.component
                {...fieldComponent.props}
                commonOptions={_.get(uiSchema.properties, 'commonOptions', false)}
                labelOrder={_.get(uiSchema.properties, 'labelOrder', '')}
                commonOptionsScore={_.get(uiSchema.properties, 'commonOptionsScore', false)}
                disabled={_.isEqual(data.fieldType, fieldTypes.RESULT)  ? true : !!disabled}
                fieldAnswer={fieldAnswer}
                footerPageY={footerPageY}
                onValuesChange={onValuesChange}
              />
            )}
          </View>
          {data.location === 'BELOW' && this.renderInstruction(data)}
        </View>
      </React.Fragment>
    )
  }

  renderStepLayout = () => {
    return this.renderScrollLayout()
  }

  renderGridLayout = () => {
    const { data, fieldAnswer,form, onValuesChange } = this.props
    const { disabled } = data
    const { fieldComponent, uiSchema } = this.state
    const borderStyle = {
      borderColor: 'grey',
      borderWidth: StyleSheet.hairlineWidth,
    }

    const fieldBorderWidth =
      uiSchema.styles && uiSchema.styles.field && uiSchema.styles.field.containerBorder
        ? StyleSheet.hairlineWidth
        : 0
    const optionBorderWidth =
      uiSchema.styles && uiSchema.styles.options && uiSchema.styles.options.containerBorder
        ? StyleSheet.hairlineWidth
        : 0
    const fieldNameWidth = parseInt(_.get(uiSchema.properties, 'fieldNameWidth') || 4)
    const fieldOptionWidth = parseInt(_.get(uiSchema.properties, 'fieldOptionWidth') || 6)
    const total = fieldNameWidth + fieldOptionWidth
    const {
      data: { uiSchema: fdUISchema },
    } = this.props
    return (
      <React.Fragment>
        <View style={componentStyles.gridLayout}>
          {/* <View style={{ flexDirection: 'row' }}> */}
          <View
            style={{
              //flex: 4,
              flex: generatePercentage(fieldNameWidth, total),
              ...borderStyle,
              borderWidth: fieldBorderWidth,
              paddingHorizontal: 3,
              marginTop: getFieldUISchemaData(fdUISchema, 'fieldName', 'top', 0),
              marginBottom: getFieldUISchemaData(fdUISchema, 'fieldName', 'bottom', 0),
            }}
          >
            <RenderHtml source={{ html: data.fieldName }} />
            <ErrorMessage id={data.id} isRequired={data.isRequired} disabled={disabled} form={form} />
            {/* {data.isRequired && <Text style={{ alignSelf: 'center', color: '#f54248' }}>*</Text>} */}
          </View>
          {/* </View> */}
          <View
            style={{
              //flex: 6,
              flex: generatePercentage(fieldOptionWidth, total),
              ...borderStyle,
              borderWidth: optionBorderWidth,
              marginTop: 10,
            }}
            
          >
            {fieldComponent && (
              <fieldComponent.component
                {...fieldComponent.props}
                disabled={!!disabled}
                layout={'horizontal'}
                commonOptions={_.get(uiSchema.properties, 'commonOptions', false)}
                labelOrder={_.get(uiSchema.properties, 'labelOrder', '')}
                commonOptionsScore={_.get(uiSchema.properties, 'commonOptionsScore', false)}
                fieldAnswer={fieldAnswer}
                isGridView={true}
                onValuesChange={onValuesChange}
              />
            )}
          </View>
        </View>
      </React.Fragment>
    )
  }
  computeStyle = () => {
    const { uiSchema } = this.state
    let styles = {
      containerStyle: {
        //padding: 3,
      },
    }
    if (uiSchema.layout === 'scroll') {
      return { ...styles }
    } else if (uiSchema.layout === 'step-form' || uiSchema.layout === 'step') {
      return { ...styles }
    } else if (uiSchema.layout === 'grid') {
      const borderStyle = uiSchema.border
        ? { borderWidth: StyleSheet.hairlineWidth, borderColor: '#D3D3D3' }
        : {}
      return {
        ...styles,
        containerStyle: {
          padding: 0,
          marginTop: 0,
          marginBottom: 0,
          ...borderStyle,
        },
      }
    }
  }
  render() {
    const { uiSchema } = this.state
    if (!uiSchema.layout) {
      return null
    }
    return (
      <View>
        {uiSchema.layout === 'scroll' && this.renderScrollLayout()}
        {uiSchema.layout === 'step' && this.renderStepLayout()}
        {uiSchema.layout === 'grid' && this.renderGridLayout()}
      </View>
    )
  }
}
const componentStyles = StyleSheet.create({
  scrollLayout: {
    backgroundColor: 'white',
    paddingHorizontal: 3,
    marginTop: 2,
    marginBottom: 10,
  },
  gridLayout: {
    flex: 1, 
    flexDirection: 'row', 
    backgroundColor: 'white',
    marginBottom: 10,
  },
});
Field.defaultProps = {
  footerPageY: 0,
  fieldAnswer: {},
  data: {},
  form: {},
  subjectTimezone: null,
  t: null,
  mode: null,
  selectedSvfId: null,
  locale: constants.locale.english,
  onValuesChange: () => {},
}
Field.propTypes = {
  footerPageY: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  fieldAnswer: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object),
  subjectTimezone: PropTypes.string,
  t: PropTypes.string,
  mode: PropTypes.string,
  selectedSvfId: PropTypes.string,
  locale: PropTypes.string,
  onValuesChange: PropTypes.func,
}
export default Field
