import _ from 'lodash'
import appConstants from '../constants/appConstants'
import createActionType from '../utils/action'
import api from '../utils/api'
import constants from '../constants/constants'
import { imageExists } from '../utils/util'
import { getUserId } from '../selectors/user'
import ConstraintStatus from '../utils/constraintKeys'
import { getAllSubjectVisitFormIds, getFormPreviewClickableImages, getForms, getQuestionnairesFormIds, getSelectedForm, getStudyData, getSubjectVisitForms, getUserClickableImages } from '../selectors/studyMetaData'
import { retrieveCrfData } from './crfData'
import { updateSelectedFormIdAndSelectedSvfId } from './session'
import { getSubject, getUser } from '../selectors/commonSelector'
import { getItem } from '../utils/secureStorageUtils'
import fieldTypes from '../constants/fieldTypes'
import fileapi from '../utils/fileapi'
import { Platform } from 'react-native'
import { getSelectedSvf } from '../selectors/subjectVisitForm'
import { getAppType } from '../selectors/storeAppStatus'

export const UPDATE_STUDY_META_DATA = createActionType('UPDATE_STUDY_META_DATA')
export const STUDY_DETAILS_RETRIEVE_REQUEST = createActionType('STUDY_DETAILS_RETRIEVE_REQUEST')
export const STUDY_DETAILS_RETRIEVE_SUCCESS = createActionType('STUDY_DETAILS_RETRIEVE_SUCCESS')
export const STUDY_DETAILS_RETRIEVE_FAILURE = createActionType('STUDY_DETAILS_RETRIEVE_FAILURE')
export const UPDATE_IMAGE_CODES = createActionType('UPDATE_IMAGE_CODES')
export const UPDATE_DOWNLOADED_IMAGE_URL = createActionType('UPDATE_DOWNLOADED_IMAGE_URL')
export const GET_META_DATA_REQUEST = createActionType('GET_META_DATA_REQUEST')
export const GET_META_DATA_FAILURE = createActionType('GET_META_DATA_FAILURE')
export const GET_META_DATA_SUCCESS = createActionType('GET_META_DATA_SUCCESS')
export const NO_META_DATA_UPDATED = createActionType('NO_META_DATA_UPDATED')
export const RETRIEVE_FORMS_WITH_FIELDS_REQUEST = createActionType('RETRIEVE_FORMS_WITH_FIELDS_REQUEST')
export const RETRIEVE_FORMS_WITH_FIELDS_SUCCESS = createActionType('RETRIEVE_FORMS_WITH_FIELDS_SUCCESS')
export const RETRIEVE_FORMS_WITH_FIELDS_FAILURE = createActionType('RETRIEVE_FORMS_WITH_FIELDS_FAILURE')
export const UPDATE_SVF_SUBMISSION_DATA = createActionType('UPDATE_SVF_SUBMISSION_DATA')
export const UPDATE_USER_STUDY_SITE = createActionType('UPDATE_USER_STUDY_SITE')
export const UPDATE_SUBJECT_CURRENT_VERSION = createActionType('UPDATE_SUBJECT_CURRENT_VERSION')
export const UPDATE_QUESTIONNAIRE_UPGRADE_LOADER = createActionType('UPDATE_QUESTIONNAIRE_UPGRADE_LOADER')

export const getOfflineData = (navigation) => async (dispatch) => {
  navigation.navigate(`${appConstants.urlPrefix}RootTabs`)
}



export const getStudyVisitMetaData = () => async (dispatch, getState) => {
  try {
    dispatch(retrieveStudyDetailsRequest())
    api.defaults.headers.common['Accept-Language'] = getSubject(getState()).locale
    const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}/studyMetaData`, { phoneNo: getUser(getState()).userId })
    dispatch({
      type: UPDATE_STUDY_META_DATA,
      data: res.data,
      userId: getUserId(getState())
    })
  } catch (error) {
    console.log(error)
    dispatch(retrieveStudyDetailsFailure())
  }
}

export const retrieveStudyDetailsRequest = (userId) => ({
  type: STUDY_DETAILS_RETRIEVE_REQUEST,
  userId,
})

export const retrieveStudyDetailsSuccess = (data, userId) => ({
  type: STUDY_DETAILS_RETRIEVE_SUCCESS,
  data,
  userId,
})

export const retrieveStudyDetailsFailure = (userId) => ({
  type: STUDY_DETAILS_RETRIEVE_FAILURE,
  userId,
})

export const getStudyDetails = (subjectId) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  try {
    dispatch(retrieveStudyDetailsRequest(userId))
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/allowedStudies`)
    if(res.status === 200){
      dispatch(retrieveStudyDetailsSuccess(res.data, userId))
    }
  } catch (error) {
    console.log(error)
    dispatch(retrieveStudyDetailsFailure(userId))
  }
}


export const getImagesAvailableForDownload =(forPreview) => async (dispatch, getState) => {
  const images = forPreview ? getFormPreviewClickableImages(getState()) : getUserClickableImages(getState())
  if(!_.isEmpty(images)){
    const tobeDownloadedImages = _.pickBy(images, img => _.isEmpty(img?.downloadedUrl))
    return tobeDownloadedImages
  }
  return []
}

//checking existing images are valid 
export const checkImagesValidAndRetryDownload = () => async(dispatch, getState) => {
  const userId = getUserId(getState())
  const imagesOfSubject =  getUserClickableImages(getState())
  let imagesValid = true
  if(!_.isEmpty(imagesOfSubject)){
    const keys = Object.keys(imagesOfSubject)
    for(const key of keys){
      const isExist = await imageExists(imagesOfSubject[key].downloadedUrl)
      if(!isExist){
        imagesOfSubject[key].downloadedUrl = null;
        imagesValid = false
      }
    }
  }
  if(!imagesValid){
    dispatch({type: UPDATE_IMAGE_CODES, data: imagesOfSubject, userId: userId})
  }
}


export const updateDownloadedUrl = (downloadedUrl, key, userId) => async (dispatch, getState) => {
  if(_.isEmpty(userId)){
    userId = getUserId(getState())
  }
  dispatch({
    type: UPDATE_DOWNLOADED_IMAGE_URL,
    key: key,
    downloadedUrl: downloadedUrl,
    userId
  })
}

const getSvfsWithSelectedSvf = (svfs, selectedSvf) => {
  const selectedSvfIndex = _.findIndex(svfs, svf => _.isEqual(svf.id, selectedSvf?.id));
  if(selectedSvfIndex !== -1){
    return svfs
  }
  return [...svfs, {...selectedSvf, isExistingForm: true}]
}
export const getMetaData =
  (subjectId, lastUpdatedDateOn, fromLogin = false) =>
  async (dispatch, getState) => {
    const studyId = getStudyData(getState())?.id
    const userId = getUserId(getState())
    const subject = getSubject(getState())
    const appType = getAppType(getState())
    const subjectMetadataRequest = {
      subjectId: subjectId,
      lastUpdatedDateOn: lastUpdatedDateOn,
    }
    dispatch({ type: GET_META_DATA_REQUEST, userId })
    try {
      const res = await api.post(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${studyId}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/subjectMetaData`,
        subjectMetadataRequest
      )
      if(res.status === 200){
      dispatch(processMetaData(res))
      const crfVersion = _.find(res.data?.versions, v => _.isEqual(v.id, subject?.currentCrfVersion?.id ))
      dispatch({ type: GET_META_DATA_SUCCESS, metaData: res.data, userId,  crfVersion })
      if (fromLogin || appType=== constants.AppType.SITESTAFF) dispatch(retrieveCrfData({subjectId: subjectId, isPrint: false}))
    } else {
      dispatch({ type: NO_META_DATA_UPDATED, userId })
    }
    } catch (error) {
      console.error(error)
      if (error.response) {
        if (error.response.status === 500 && error.response.data && Number(error.response.data) == ConstraintStatus.NO_META_DATA_UPDATED) {
          dispatch({ type: NO_META_DATA_UPDATED, userId })
        }else{
          dispatch({ type: GET_META_DATA_FAILURE, userId })
        }
      } else {
        dispatch({ type: GET_META_DATA_FAILURE, userId })
      }
      throw error;
    }
  }

  export const processMetaData = (res) => async (dispatch, getState) => {
    const data = res.data;
    const selectedSvf = getSelectedSvf(getState());
    if(!_.isEmpty(selectedSvf)){
      const subjectVisits = _.map(res.data?.subjectVisits, visit => {
        return {
          ...visit,
          subjectVisitForms: getSvfsWithSelectedSvf(visit?.subjectVisitForms, selectedSvf)
        }
      })
      data.subjectVisits = subjectVisits
    }
  }

  //Checking whether the user's all configured images are downloaded or not
  export const getClickableImagesConfigured  = () => async (dispatch, getState) => {
  const subjectVisitFormList = getSubjectVisitForms(getState())
  const formList = getForms(getState())
  const userId = getUserId(getState())
  const imagesOfSubject =  getUserClickableImages(getState())
  let imagesAvailables = false
  _.forEach(subjectVisitFormList, svf => {
    const fields = getFieldsOfSubjectVisitForm(svf, formList)
    const clickableImageFields = _.filter(fields, field => _.isEqual(field?.fieldType, fieldTypes.CLICKIMAGE))
    const imagesTobeDownloaded =_.filter(clickableImageFields, imageField => _.isEmpty(imagesOfSubject[imageField?.dictionary?.imageCode]) || _.isEmpty(imagesOfSubject[imageField?.dictionary?.imageCode]?.downloadedUrl))
    if(!_.isEmpty(imagesTobeDownloaded)){
      imagesAvailables = true
      _.forEach(imagesTobeDownloaded, fld => {
        imagesOfSubject[fld?.dictionary?.imageCode] = {
          fieldUrl: fld?.dictionary?.img?.src
        }
    })
   }
  });
  if(imagesAvailables){
    dispatch({type: UPDATE_IMAGE_CODES, data: imagesOfSubject, userId: userId})
  }else if(!imagesAvailables && !_.isEmpty(imagesOfSubject) && Platform.OS == 'web'){
    await dispatch(checkImagesValidAndRetryDownload())
  }
  }

  
const getFieldsOfSubjectVisitForm = (svf, allFormList) => {
  let form = _.filter(allFormList, form => _.isEqual(form?.id, svf?.form?.id))?.[0]
  return !_.isEmpty(form) ? _.flatten(_.map(form.fieldGroups, (fg) => fg.fields)) || [] : []
}

const retrievePreviewQuestionnairesStudyLevel =  async (requestProps) => {
  const {formId, primaryOrgCode, studyId, dispatch} = requestProps
  const userId = constants.AppType.PREVIEW  
  try {
      const locale = getItem('locale') || constants.locale.english;
      if (!_.isEmpty(formId)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST, userId })
        const res = await api.post(`/${primaryOrgCode}/studies/${studyId}/forms`, {
          formIds: [formId],
          locale
        })
        storePreviewFormInfo(dispatch, res.data, formId, userId) 
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE , userId})
    }
  }

const storePreviewFormInfo = (dispatch, formList, formId, userId, svfId=null) => {
  const loFormList = handleDictionaryInQuestionnaires(formList, null)
  dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_SUCCESS, data: loFormList, userId })
  dispatch(updateImages(loFormList[0]))   
  dispatch(updateSelectedFormIdAndSelectedSvfId(formId, svfId, userId))
}

export const retrievePreviewQuestionnairesFormLevel =  async (requestProps) => {
  const locale = getItem('locale') || constants.locale.english;
  const userId = constants.AppType.PREVIEW 
  const {clientId, formId, primaryOrgCode, dispatch} = requestProps
  try {
      const formIdList =  [formId];
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST, userId })
        const res = await api.post(`/${primaryOrgCode}/forms/${formId}?clientId=${clientId}`, {locale})
        storePreviewFormInfo(dispatch, res.data, formId, userId)
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE, userId })
    }
  }

const handleDictionaryInQuestionnaires = (formList, selectedForm) => {

 const forms = _.map(formList, form => {
    const loFieldGroups = _.map(form.fieldGroups, (fg) => {
      const fields = _.map(fg.fields, (field) => {
        if (field.dictionary) {
          return {
            ...field,
            dictionary:
              typeof field.dictionary !== 'string'
                ? field.dictionary
                : JSON.parse(field.dictionary),
            enDictionary:
              typeof field.enDictionary !== 'string'
                ? field.enDictionary
                : JSON.parse(field.enDictionary),
          }
        }
        return field
      })
      fg.fields = fields
      return fg
    })
    form.fieldGroups = loFieldGroups
    return form
  })
  if(!_.isEmpty(selectedForm)){
    const selectedFormIndex = _.find(forms, form => _.isEqual(form.id, selectedForm.id))
    return selectedFormIndex !== -1 ? forms : [forms, selectedForm]
  }
  return forms;
}


export const retrieveFormsIfNotExist = () => async (dispatch, getState) => {
  const exisitingFormIds = getQuestionnairesFormIds(getState()) 
  const metadataFormIds = getAllSubjectVisitFormIds(getState())
  const formIdsDiff1 = _.difference(metadataFormIds, exisitingFormIds)
  const formIdsDiff2 = _.difference(exisitingFormIds, metadataFormIds)
  if (!_.isEmpty(formIdsDiff1) || !_.isEmpty(formIdsDiff2)) {
    dispatch(retrieveFormWithFields({userId: getUserId(getState())}))
  }
}

export const updateSvfStatus =
  (svf, subjectVisitId, status, completedDate, svfStatus) =>
  async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SVF_SUBMISSION_DATA,
      svfId: svf,
      subjectVisitId,
      status,
      completedDate,
      svfStatus,
      userId: getUserId(getState())
    })
  }



  export const retrievePrintQuestionnairesStudyLevel = async (requestProps) => {
    const userId = constants.AppType.PRINT
    const locale = getItem('locale') || constants.locale.english;
    const {formId, svfId, dispatch, primaryOrgCode, studyId} = requestProps
    try {
      const formIdList = [formId]
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST, userId })
        const res = await api.post(`/${primaryOrgCode}/studies/${studyId}/forms`, {
          formIds: _.uniqBy(formIdList),
          locale
        })
        storePreviewFormInfo(dispatch, res.data, formId, userId, svfId) 
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE, userId })
    }
  }


  export const retrievePrintQuestionnaireBySvfId = async (requestProps) => {
    const {formId, dispatch, primaryOrgCode, studyId, siteId, svfId} = requestProps
    const userId = constants.AppType.PRINT
    try {
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST, userId })
      const res = await api.get(`/${primaryOrgCode}/studies/${studyId}/sites/${siteId}/subjectVisitForms/${svfId}`)
      storePreviewFormInfo(dispatch, [res.data], formId, userId, svfId)       
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE, userId })
    }
  }

  export const retrievePrintQuestionnairesFormLevel = async(requestProps)  => {
    const {clientId, formId, primaryOrgCode, dispatch} = requestProps
    const userId = constants.AppType.PRINT
    const locale = getItem('locale') || constants.locale.english;
    try {
      const formIdList =  [formId];
      if (!_.isEmpty(formIdList)) {
        dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST, userId })
        const res = await api.post(`/${primaryOrgCode}/forms/${formId}?clientId=${clientId}`, {locale})
        storePreviewFormInfo(dispatch, res.data, formId, userId)  
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE, userId})
    }
  }

export const updateUserStudySite = (studySite, userId) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER_STUDY_SITE,
      studySite,
      userId
    })
  }

/* This method is to store clickable images for form when the app is preview mode or print mode */
const updateImages = (form) => async (dispatch) => {
  const fieldGroups = !_.isEmpty(form) ?  form.fieldGroups: [];
  const fieldList = [];
  _.forEach(fieldGroups, (fg) => {
    const clkFields = _.filter(fg.fields, (field) => _.isEqual(field.fieldType, fieldTypes.CLICKIMAGE));
    _.map(clkFields, (field) => {
      fieldList.push(field);
    });
  })
  const clickableImageFields = _.filter(fieldList, field => _.isEqual(field?.fieldType, fieldTypes.CLICKIMAGE))
  const imagesOfPreview = {
  }
  _.forEach(clickableImageFields, fld => {
    imagesOfPreview[fld?.dictionary?.imageCode] = {
        fieldUrl: fld?.dictionary?.img?.src
      }
  })
  dispatch({type: UPDATE_IMAGE_CODES, data: imagesOfPreview, userId: constants.AppType.PREVIEW})
}

//To download clickable images to store locally
export const downloadClickableImage =  async(fileName) => {
  try {
    return await fileapi.get(`/clickableImage/download?fileName=${fileName}`, { responseType: 'blob'})  
  } catch (error) {
    console.log(error)
  }
}

export const retrieveFormWithFields = (requestProps) => async (dispatch, getState) => {
  const {userId: type} = requestProps
  if(type === constants.AppType.PRINT){
   return retrievePrintQuestionnaires({...requestProps, dispatch, state: getState()})
  }else if(type === constants.AppType.PREVIEW){
    return retrievePreviewQuestionnaires({...requestProps, dispatch, state: getState()})
  }else{
    return retrieveQuestionnaires({...requestProps, dispatch, state: getState()})
  }
}

//to retrieve all forms from all subjectVisits
const retrieveQuestionnaires = async(requestProps) => {
  const {dispatch, userId, state} = requestProps
    try {
      const locale = getItem('locale') || constants.locale.english;
      const formIdList = getAllSubjectVisitFormIds(state)
        if (!_.isEmpty(formIdList)) {
            dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_REQUEST,  userId: userId })
            const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/forms`, {
              formIds: _.uniqBy(formIdList),
              locale
            })
            const selectedForm = getSelectedForm(state);
            dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_SUCCESS, data: handleDictionaryInQuestionnaires(res.data, selectedForm), userId: userId })
        }
    } catch (error) {
      console.log(error)
      dispatch({ type: RETRIEVE_FORMS_WITH_FIELDS_FAILURE, userId: userId })
    }
}

const retrievePreviewQuestionnaires = async(requestProps) => {
  const {formLevel} = requestProps
  if(_.isEqual(formLevel, constants.targetEntityType.STUDY)){
    await retrievePreviewQuestionnairesStudyLevel(requestProps)
  }else{
    await retrievePreviewQuestionnairesFormLevel(requestProps)
  }
}

const retrievePrintQuestionnaires = async(requestProps) => {
  const {svfId, formLevel} = requestProps
  if (svfId) {
    await retrievePrintQuestionnaireBySvfId(requestProps)
  } else if(_.isEqual(formLevel, constants.targetEntityType.STUDY)){
    await retrievePrintQuestionnairesStudyLevel(requestProps)
  }else{
    await retrievePrintQuestionnairesFormLevel(requestProps)
  }
}

export const getSubjectCurrentVersion = (subjectId) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  try {
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/current-version`)
    dispatch({ type: UPDATE_SUBJECT_CURRENT_VERSION, crfVersion: res.data, userId })
  } catch (error) {
    dispatch({ type: UPDATE_SUBJECT_CURRENT_VERSION, crfVersion: null, userId })
    console.log(error)
  }
}
export const updateStudyMetaData = (forceUpdate = false) => async (dispatch, getState) => {
  const subject = getSubject(getState());
  if(_.isEmpty(subject)){
    return
  }
  try {
    dispatch({type: UPDATE_QUESTIONNAIRE_UPGRADE_LOADER, userId: getUserId(getState()), isVersionUpgrading: true})
    if(forceUpdate){
      await dispatch(getSubjectCurrentVersion(subject.id))
    }
    await dispatch(getMetaData(subject?.id, !forceUpdate ? subject?.lastUpdatedDateOn : null))
    await dispatch(getStudyDetails(subject?.id))
    await dispatch(retrieveFormsIfNotExist())
    dispatch({type: UPDATE_QUESTIONNAIRE_UPGRADE_LOADER, userId: getUserId(getState()), isVersionUpgrading: false})
  } catch (error) {
    console.log("Failed to update meta data", error)
    dispatch({type: UPDATE_QUESTIONNAIRE_UPGRADE_LOADER, userId: getUserId(getState()), isVersionUpgrading: false})
  } 
}




