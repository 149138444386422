import React, { Component } from 'react'
import { View, Platform } from 'react-native'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import PropTypes from 'prop-types'
import constants from '../../constants/constants'
import Datepicker from '../webDatePicker/Datepicker'
import moment from 'moment'
import { getTimezoneCode } from '../../utils/util'
import ErrorMessage from '../form/ErrorMessage'

const {dateFormats, datepickerMode} = constants

export class DateInput extends Component {
  
  changeDate = (date) => {
    const {
      field: { id }, onValuesChange
    } = this.props
    onValuesChange(id, date)
    return date
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema },
      t,
      disabled,
      fieldAnswer,
      locale,
      form,
      subjectTimezone
    } = this.props
    const crfData = { ...fieldAnswer }
    const rules = isRequired && !disabled ? [
      {
        required: true,
        message: t('ReqField'),
        whitespace: true,
      },
    ] : []

    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {form.getFieldDecorator(id, {
          initialValue: crfData?.fieldValue || null,
          valuePropName: Platform.OS === 'web' ? 'date' : 'value',
          rules,
          getValueFromEvent: this.changeDate,
        })(
          <Datepicker
            showTimeInput={false}
            placeHolder={t('SelectDate')}
            valueFormat={dateFormats.DATE}
            disabled={disabled}
            testID={`DateInput-Field-${id}-view`}
            locale={locale}
            t={t}
            mode={datepickerMode.date}
            date={crfData?.fieldValue}
            timezone={subjectTimezone}
            shortTimezone={getTimezoneCode(subjectTimezone)}
          />
        )}
        <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />
      </View>
    )
  }
}

DateInput.defaultProps = {
  t: () => {},
  disabled: false,
  fieldAnswer: {},
  subjectTimezone: moment.tz.guess(),
}

DateInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  t: PropTypes.func,
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.object,
  locale: PropTypes.string.isRequired,
  subjectTimezone: PropTypes.string,
  onValuesChange: PropTypes.func.isRequired,
}
