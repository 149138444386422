import {
  RETRIEVE_ALL_CRF_DATA_FAILURE,
  RETRIEVE_ALL_CRF_DATA_REQUEST,
  RETRIEVE_ALL_CRF_DATA_SUCCESS,
  UPDATE_FIELD_ANSWER,
  UPDATE_FIELD_ANSWER_SUCCESS,
} from '../actions/crfData'
import initialState from '../store/initialState'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import moment from 'moment'
export default (state = initialState.crfData, action) => {
  switch (action.type) {
    case RETRIEVE_ALL_CRF_DATA_REQUEST:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          loading: true,
        }
      }
    case RETRIEVE_ALL_CRF_DATA_FAILURE:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          loading: false,
        }
      }
    case RETRIEVE_ALL_CRF_DATA_SUCCESS: {
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          ...Object.entries(action.formAnswers).reduce((acc, [svfId, fields]) => ({
            ...acc,
            [svfId]: {
              ...Object.entries(fields).reduce((fieldAcc, [fieldId, field]) => ({
                ...fieldAcc,
                [fieldId]: state?.[action.userId]?.[svfId]?.[fieldId]?.isUpdated ? 
                  state[action.userId][svfId][fieldId] : 
                  field
              }), {})
            }
          }), {}),
          loading: false,
        }
      }
    }
    case UPDATE_FIELD_ANSWER: {
      const fieldId = Object.keys(action.selectedField)[0]
      const selectedFieldValue = action.selectedField[fieldId]
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
        [action.svfId]: {
          ...state?.[action.userId]?.[action.svfId],
          [fieldId]: {
            ...state?.[action?.userId]?.[action?.svfId]?.[fieldId],
            fieldValue: selectedFieldValue,
            optionOid: selectedFieldValue,
            submissionTs: moment.utc().format(),
            isUpdated: true,
          }
        }
      }
    }
    }
    case UPDATE_FIELD_ANSWER_SUCCESS: {
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
        [action.svfId]: {
          ...Object.keys(state?.[action.userId]?.[action.svfId] || {}).reduce((acc, key) => ({
            ...acc,
            [key]: {
              ...state?.[action.userId]?.[action.svfId]?.[key],
              isUpdated: false
            }
          }), {}),
          
        }
      }
    }
    }
    case CLEAR_USER_STORAGE: 
      return {
        ...state,
        [action.userId]: {
          ...Object.entries(state?.[action.userId] || {}).reduce((acc, [svfId, fields]) => {
            const updatedFields = Object.entries(fields).reduce((fieldAcc, [fieldId, field]) => {
              if (field?.isUpdated) {
                fieldAcc[fieldId] = field;
              }
              return fieldAcc;
            }, {});
            
            if (Object.keys(updatedFields).length > 0) {
              acc[svfId] = updatedFields;
            }
            return acc;
          }, {})
        }
      }
    default:
      return state
  }
}
