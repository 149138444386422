export default {
  appStatus: {
    isDeviceOnline: true,
    syncQueueTimerId: null,
    appType: null, 
    storage: {
      isStorageAvailable: true,
      isSpaceAlertAcknowledged: false,
    },
    timers: {
      downloadImageTimerId: null,
    },
    onMobile: false,
    isPinValidated: false,
    loading: false
  },
  studyMetaData: {
    
  },
  subject: {
   
  },
  crfData: {
  },
  chat: {
   
  },
  users: {
    
  },
  session: {
   currentUserId: null,
  },
  syncQueue: {
    
  }, 
  partialDataQueue: {

  },
  trainingForms: {

  }
}