import React from 'react'
import PropTypes from 'prop-types'
import { styles } from '../styles'
import CustomTextInput from '../../common/CustomTextInput'

export const TextArea = React.memo(({ t, disabled, changeFieldValue, value }) => {
  return (
    <CustomTextInput
      onChangeText={changeFieldValue}
      placeholder={t('EnterHere')}
      editable={!disabled} 
      multiline={true}
      value={value}
      style={[{ height: 100 }, styles.textArea, styles.textBoxBorderColor]}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.disabled === nextProps.disabled && prevProps.value === nextProps.value;
})

TextArea.defaultProps = {
  t: (key) => key,
  disabled: false,
  changeFieldValue: () => {},
  value: null,
}

TextArea.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  changeFieldValue: PropTypes.func,
  value: null,
}